import { useState, useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
// import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

function RoutePreview({ contract }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const API_KEY = 'XEdmcrmYxgdDYaz9PZXk_KSVCILoOhnzr0T4I3Yz6V8';

  const zoom = 7;
  const coordsPrague = [14.4009399, 50.0711206];
  const coordsBrno = [16.5661545, 49.1747438];

  const initMap = (geometry) => {
    console.log(geometry)
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      center: [14.8981184, 49.8729317],
      zoom,
      style: {
        version: 8,
        sources: {
          // style for map tiles
          'basic-tiles': {
            type: 'raster',
            url: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${API_KEY}`,
            tileSize: 256,
          },
          // style for our geometry
          'route-geometry': {
            type: 'geojson',
            data: {
              type: 'LineString',
              coordinates: geometry.geometry.coordinates,
            },
          },
        },
        layers: [
          {
            id: 'tiles',
            type: 'raster',
            source: 'basic-tiles',
          },
          {
            id: 'route-geometry',
            type: 'line',
            source: 'route-geometry',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0033ff',
              'line-width': 8,
              'line-opacity': 0.6,
            },
          },
        ],
      },
    });

    map.current.on("load", async () => {
      console.log('map loaded')
    })
  }

  const route = async () => {
    try {
      const url = new URL(`https://api.mapy.cz/v1/routing/route`);

      url.searchParams.set('apikey', API_KEY);
      url.searchParams.set('lang', 'cs');
      url.searchParams.set('start', coordsPrague.join(','));
      url.searchParams.set('end', coordsBrno.join(','));
      // other possible routeType values include: car_fast, car_fast_traffic, car_short, foot_fast, bike_road, bike_mountain
      url.searchParams.set('routeType', 'car_fast_traffic');
      // if you want to avoid paid routes (eg. highways) set this to true
      url.searchParams.set('avoidToll', 'false');

      const response = await fetch(url.toString(), {
        mode: 'cors',
      });
      const json = await response.json();

      // we output the length and duration of the result route to the console
      console.log(
        `length: ${json.length / 1000} km`,
        `duration: ${Math.floor(json.duration / 60)}m ${json.duration % 60}s`
      );

      initMap(json.geometry)

      // then we set the retrieved data as the geometry of our geojson layer
      // const source = map.current.getSource('route-geometry');

      // if (source && json.geometry) {
      //   console.log('test')
      //   console.log(json.geometry)
      //   source.setData(json.geometry);
      //   // finally we set the map to show the whole geometry in the viewport
      //   map.current.jumpTo(
      //     map.current.cameraForBounds(bbox(json.geometry.geometry.coordinates), {
      //       padding: 40,
      //     })
      //   );
      // }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once
    if (!mapContainer.current) return

    route()
  }, [API_KEY, zoom]);

  return (
    <div className='map-wrap'>
      <div className='route-preview' ref={mapContainer}></div>
    </div>
  );
}

export default RoutePreview;

function bbox(coords) {
  let minLatitude = Infinity;
  let minLongitude = Infinity;
  let maxLatitude = -Infinity;
  let maxLongitude = -Infinity;

  coords.forEach((coor) => {
    minLongitude = Math.min(coor[0], minLongitude);
    maxLongitude = Math.max(coor[0], maxLongitude);
    minLatitude = Math.min(coor[1], minLatitude);
    maxLatitude = Math.max(coor[1], maxLatitude);
  });

  return [
    [minLongitude, minLatitude],
    [maxLongitude, maxLatitude],
  ];
}
