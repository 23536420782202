class SyncStatus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <span className={classNames('badge', {
          'badge-warning': this.props.status == 'waiting',
          'badge-danger': this.props.status == 'error'
        })}>
        {I18n.t('sync-statuses.'+this.props.status)}
      </span>
    )
  }
}

export default SyncStatus
