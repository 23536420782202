import Comment from './comment'
import Loading from './loading'

class Comments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      loading: true,
      text: ''
    };
  }

  componentDidMount() {
    this.fetchComments()
    window.comments = this
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.contractId != this.props.contractId) ||
      (prevProps.tab != 'comments' && this.props.tab == 'comments')
    ) {
      this.setState({comments: []}, () => {
        this.fetchComments()
      })
    }
  }

  componentWillUnmount() {
    window.comments = undefined
  }

  fetchComments(silent=false) {
    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/comments',
      data: {
        contract_id: this.props.contractId,
        customer_id: this.props.customerId
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        if (!silent) this.setState({loading: true})
        this.currentRequest = null
      },
      success: (data) => {
        this.setState({comments: data.results})
      },
      complete: () => {
        if (!silent) this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  postComment() {
    const currentRequest = jQuery.ajax({
      method: 'POST',
      url: '/api/v1/comments',
      data: {
        comment: {
          contract_id: this.props.contractId,
          customer_id: this.props.customerId,
          text: this.state.text
        }
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.setState({loading: true})
        this.currentRequest = null
      },
      success: (data) => {
        this.setState((state, props) => {
          let comments = state.comments
          comments.unshift(data)
          return {comments: comments, text: ''}
        })
      },
      complete: () => {
        this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  handleChange(e) {
    this.setState({text: e.target.value})
  }

  handleSubmit(e) {
    if (e) e.preventDefault()
    this.postComment()
  }

  handleDelete(comment, e) {
    if (e) e.preventDefault()
    if (confirm('Opravdu smazat komentář?')) {
      // 1) Delete from the state
      this.setState((state, props) => {
        return {
          comments: state.comments.filter(i => i.id != comment.id)
        }
      })

      // 2) AJAX
      jQuery.ajax({
        method: 'DELETE',
        url: `/api/v1/comments/${comment.id}`,
        beforeSend: (xhr) => {
          if (this.props.accessToken) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }
        },
        success: (data) => {
        },
        complete: () => {
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="comment-form">
          <div className="form-group">
            <textarea
              placeholder="Napište komentář"
              className="form-control"
              name="text"
              id="text"
              value={this.state.text}
              onChange={this.handleChange.bind(this)}>
            </textarea>
          </div>
          <div className="text-right">
            <button
              disabled={!this.state.text.replace(/\s/g,'').length || this.state.loading}
              className="btn btn-success"
              onClick={this.handleSubmit.bind(this)}>
              Odeslat komentář
            </button>
          </div>
        </div>
        <div className="comments changelog">
          {this.state.loading ? (
            <Loading />
          ) : null}

          {this.state.comments.map(comment =>
            <Comment
              key={comment.id}
              comment={comment}
              showContractId={this.props.customerId}
              handleDelete={this.handleDelete.bind(this)} />
          )}
        </div>

      </React.Fragment>
    )
  }
}

export default Comments
