import defaultAvatar from 'images/avatar.png';

const ShortMessage = ({ shortMessage }) => {
  return (
    <div className='comment change' data-id={shortMessage.id}>
      <div className='body'>
        <div className='time'>
          <span>{moment(shortMessage.created_at).format('LLL')}</span>
          <span>{' | '}</span>
          <span>{shortMessage.recipients.join(', ')}</span>
          <span>{' | '}</span>
          <span>{shortMessage.status_text}</span>
          {shortMessage.delivered && (
            <>
              <span>{' | '}</span>
              <span>Doručeno</span>
            </>
          )}
        </div>
        <div className='message'>{shortMessage.message}</div>
      </div>
      <div className='user'>
        <div className='avatar'>
          <img
            src={
              shortMessage.user ? shortMessage.user.avatar_url : defaultAvatar
            }
            alt=''
          />
        </div>
        <div className='name'>
          {shortMessage.user ? shortMessage.user.name : 'Smazaný'}
        </div>
      </div>
    </div>
  );
};

export default ShortMessage;
