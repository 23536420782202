import { useState } from 'react';

import Loading from '../loading';
import Modal from '../modal';
import ManufacturerModalBody from '../manufacturer_modal_body';

function ManufacturersDocs({ master }) {
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const showModal = (e, manufacturer) => {
    e.preventDefault();
    e.stopPropagation();

    let modalBody = <ManufacturerModalBody manufacturer={manufacturer} />;

    ReactDOM.render(
      React.createElement(Modal, {
        title: `Soubory ${manufacturer.name}`,
        body: modalBody
      }),
      document.querySelector('#modal-container')
    );
  };

  if (master.state.loading) {
    return <Loading />;
  }

  let manufacturersDocs = master.state.manufacturers_docs;
  if (search) {
    let query = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let re = new RegExp(query, 'i');
    manufacturersDocs = manufacturersDocs.filter((i) => i.name.search(re) >= 0);
  }

  return (
    <>
      <div className='card bg-light'>
        <div className='card-body'>
          <div className='row'>
            <div className='col'>
              <input
                placeholder='Hledat'
                className='form-control'
                type='text'
                name='search'
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='table-wrapper'>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Název</th>
              <th>Souborů</th>
              <th className='text-right'></th>
            </tr>
          </thead>
          <tbody>
            {manufacturersDocs.map((manufacturer) => (
              <tr
                key={manufacturer.id}
                onClick={(e) => showModal(e, manufacturer)}
              >
                <td>{manufacturer.id}</td>
                <td>{manufacturer.name}</td>
                <td>{manufacturer.uploads.length}</td>
                <td className='text-right'>
                  <button className='btn btn-sm btn-outline-secondary'>
                    Zobrazit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ManufacturersDocs;
