import Helper from './helper'

export function handleEmailPhoneChange(uuid, data, e) {
  let helperData = this.state.customer[data].map(i => {
    if (i.uuid == uuid) {
      return Object.assign(
        {},
        i,
        {
          [e.target.name]: (e.target.type == 'checkbox' ? e.target.checked : e.target.value)
        }
      )
    } else {
      return i
    }
  })

  this.setState((state, props) => {
    return {
      customer: Object.assign(state.customer, {[data]: helperData})
    }
  })
}

export function addRemoveEmailPhone(action, data, e) {
  e.preventDefault()

  this.setState((state, props) => {
    let helperData = state.customer[data]

    switch(action) {
      case 'add':
        helperData = helperData.concat({
          uuid: Helper.uuid(),
          value: '',
          note: '',
          default: false
        })
        break
      default:
        if (confirm('Opravdu odebrat?')) {
          helperData = helperData.filter(i => i.uuid != action)
        }
        break
    }

    return {
      customer: Object.assign(
        state.customer,
        {[data]: helperData}
      )
    }
  })
}

export function addDevice(e) {
  e.preventDefault()
  let currentUUID = this.state.contract?.place_uuid || this.state.activePlaceUUID
  let places = this.state.customer.places.map(i => {
    if (i.uuid == currentUUID) {
      i.devices.push({
        manufacturer: '',
        manufacturer_id: '',
        type: '',
        uuid: Helper.uuid(),
        active: true,
        v: '2',
        warranty_until: ''
      })
      return i
    } else {
      return i
    }
  })

  let customer = Object.assign({}, this.state.customer, {places: places})
  this.setState({customer: customer})
}

export function removeDevice(uuid, e) {
  e.preventDefault()
  let places = this.state.customer.places.map(i => {
    if (i.uuid == this.state.contract?.place_uuid || this.state.activePlaceUUID) {
      i.devices = i.devices.map(device => {
        if (device.uuid == uuid) {
          device.delete = true
          return device
        } else {
          return device
        }
      })
      return i
    } else {
      return i
    }
  })

  let customer = Object.assign({}, this.state.customer, {places: places})
  this.setState({customer: customer})
}

export function handleDeviceChange(uuid, e) {
    let h
    if (e == null || e.label) {
      if (e) {
        h = {manufacturer: e.label, manufacturer_id: e.value}
      } else {
        h = {manufacturer: '', manufacturer_id: ''}
      }
    } else if (e.target.name == 'age') {
      let year = ''
      if (e.target.value) {
        year = new Date().getFullYear() - parseInt(e.target.value)
      }
      h = {year: year}
    } else {
      if (e.target.type != 'checkbox') e.preventDefault()
      h = {[e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value}
    }
    let places = this.state.customer.places.map(i => {
      if (i.uuid == this.state.contract?.place_uuid || this.state.activePlaceUUID) {
        let devices = i.devices.map(device => {
          if (device.uuid == uuid) {
            return Object.assign(device, h)
          } else {
            return device
          }
        })
        return Object.assign(i, {devices: devices})
      } else {
        return i
      }
    })

    let customer = Object.assign({}, this.state.customer, {places: places})
    this.setState({customer: customer})
  }
