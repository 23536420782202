import Helper from '../helpers/helper';

class Uploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files || [],
      hiddenUUIDS: [],
    };
  }

  performUploadsToSync(file) {
    let _this = this;

    // Update contractsToSync & contracts (we assume successful post? we should NOT)
    _this.props.master.setState((state, props) => {
      let uploadsToSync = state.uploadsToSync.concat();

      // CASE 1
      if (uploadsToSync.find((i) => i.uuid == file.uuid)) {
        uploadsToSync = uploadsToSync.map((i) => {
          if (i.uuid == file.uuid) {
            return {
              syncStatus: 'waiting',
              uuid: file.uuid,
              device_uuid: file.device_uuid,
            };
          } else {
            return i;
          }
        });
      } else {
        uploadsToSync.push({
          syncStatus: 'waiting',
          uuid: file.uuid,
          device_uuid: file.device_uuid,
          contractId: this.props.contractId,
          kind: this.props.kind,
        });

        if (file.file) localforage.setItem(file.uuid, file.file);
      }

      return {
        uploadsToSync: uploadsToSync,
      };
    });

    _this.setState((state, props) => {
      return {
        hiddenUUIDS: state.hiddenUUIDS.concat(file.uuid),
      };
    });

    alert('Chyba spojení, data byly uloženy k pozdější synchronizaci.');

    // CASE 2
    // let reader = new FileReader()
    // reader.addEventListener("load", function () { // Setting up base64 URL on image
    //   let url = reader.result
    //
    //   if (uploadsToSync.find(i => i.uuid == file.uuid)) {
    //     uploadsToSync = uploadsToSync.map(i => {
    //       if (i.uuid == file.uuid) {
    //         return {
    //           syncStatus: 'waiting',
    //           uuid: file.uuid,
    //           url: url,
    //           filename: file.file.name,
    //           lastModified: file.file.lastModified,
    //           lastModifiedDate: file.file.lastModifiedDate,
    //           content_type: file.file.type,
    //           size: file.file.size
    //         }
    //       } else {
    //         return i
    //       }
    //     })
    //   } else {
    //     uploadsToSync.push({
    //       syncStatus: 'waiting',
    //       uuid: file.uuid,
    //       url: url,
    //       filename: file.file.name,
    //       lastModified: file.file.lastModified,
    //       lastModifiedDate: file.file.lastModifiedDate,
    //       content_type: file.file.type,
    //       size: file.file.size
    //     })
    //   }
    //
    //   // Update contractsToSync & contracts (we assume successful post? we should NOT)
    //   _this.props.master.setState({
    //     uploadsToSync: uploadsToSync
    //   })
    //
    //   alert('Chyba spojení, data byly uloženy k pozdější synchronizaci.')
    // }, false);
    //
    // reader.readAsDataURL(file.file)
  }

  uploadFile(file) {
    let data;
    let _this = this;

    data = new FormData();
    data.append('upload[uuid]', file.uuid);
    data.append('upload[device_uuid]', file.device_uuid);
    if (file.file) {
      data.append('upload[asset]', file.file);
      data.append('upload[content_type]', file.file.type);
      data.append('upload[filename]', file.file.name);
    }

    if (this.props.contractId)
      data.append('upload[contract_id]', this.props.contractId);
    if (this.props.kind) data.append('upload[kind]', this.props.kind);
    if (this.props.manufacturer_id)
      data.append('upload[manufacturer_id]', this.props.manufacturer_id);

    // Offline
    let skipNetwork = false;
    if (this.props.master && !this.props.master.state.online) {
      skipNetwork = true;
      this.performUploadsToSync(file);
    }

    if (!skipNetwork) {
      file.request = jQuery.ajax({
        url: '/api/v1/uploads',
        data: data,
        method: 'POST',
        processData: false,
        contentType: false,
        // contentType: 'multipart/form-data',
        // mimeType: 'multipart/form-data',
        beforeSend: (xhr) => {
          if (_this.props.master && _this.props.master.state.accessToken) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + _this.props.master.state.accessToken.access_token
            );
          } else {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + document.body.dataset.jwt
            );
          }
        },
        success: (data) => {
          this.setState((state, props) => {
            return {
              files: state.files.map((i) => {
                if (i.uuid == data.uuid) {
                  return Object.assign(i, {
                    asset_url: data.asset_url,
                    uploaded: true,
                  });
                } else {
                  return i;
                }
              }),
            };
          });

          if (this.props.handleUploads) {
            this.props.handleUploads(data, this.props.kind);
          }
        },
        error: (data) => {
          if (data.responseJSON) {
            console.log(data.responseJSON);

            if (data.responseJSON.uuid) {
              this.setState((state, props) => {
                return {
                  files: state.files.map((i) => {
                    if (i.uuid == data.responseJSON.uuid) {
                      return Object.assign(i, {
                        full_messages: data.responseJSON.full_messages,
                      });
                    } else {
                      return i;
                    }
                  }),
                };
              });
            }
          } else {
            if (data.responseJSON) {
              console.log(data.responseJSON);
            } else {
              if (data.readyState == 0) {
                _this.performUploadsToSync(file);
              } else {
                alert('Neočekávaná chyba');
              }
            }
          }
        },
        complete: () => {
          this.setState({ loading: false });
          file.request = null;
        },
        xhr: () => {
          var xhr = new window.XMLHttpRequest();
          //Upload progress
          xhr.upload.addEventListener(
            'progress',
            function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                //Do something with upload progress
                file.progress = percentComplete;
                _this.forceUpdate();
              }
            },
            false
          );
          //Download progress
          // xhr.addEventListener("progress", function(evt){
          //   if (evt.lengthComputable) {
          //     var percentComplete = evt.loaded / evt.total;
          //     //Do something with download progress
          //     console.log(percentComplete);
          //   }
          // }, false);
          return xhr;
        },
      });
    }
  }

  handleFileChange(e) {
    let _this = this;
    Array.from(e.target.files).forEach((file) => {
      // let reader = new FileReader()
      let h = {
        uuid: Helper.uuid(),
        file: file,
        filename: file.name,
        content_type: file.type,
        uploaded: false,
        progress: 0,
      };

      this.uploadFile(h);

      _this.setState(
        (state, props) => {
          return {
            files: state.files.concat(h),
          };
        },
        () => {
          if (_this.props.handleUpdateUploads) {
            _this.props.handleUpdateUploads(
              _this.props.name,
              _this.state.files.map((i) => i.uuid)
            );
          }
        }
      );

      // reader.addEventListener("load", function () { // Setting up base64 URL on image
      //   h.url = reader.result
      //   _this.setState({files: _this.state.files.concat(h)}, () => {
      //     if (_this.props.handleUpdateUploads) {
      //       _this.props.handleUpdateUploads(_this.props.name, _this.state.files.map(i => i.uuid))
      //     }
      //   })
      // }, false);
      //
      // reader.readAsDataURL(file)
    });
    e.target.value = null;
  }

  handleFileRemove(file, e) {
    e.preventDefault();
    if (file.request) file.request.abort();

    if (this.props.manufacturer_id) {
      if (confirm('Opravdu odebrat?')) {
        let _this = this
        jQuery.ajax({
          url: `/api/v1/uploads/${file.id}`,
          method: 'DELETE',
          beforeSend: (xhr) => {
            if (_this.props.master && _this.props.master.state.accessToken) {
              xhr.setRequestHeader(
                'Authorization',
                'Bearer ' + _this.props.master.state.accessToken.access_token
              );
            } else {
              xhr.setRequestHeader(
                'Authorization',
                'Bearer ' + document.body.dataset.jwt
              );
            }
          },
        });
      }
    }

    let files = this.state.files.filter((i) => i.uuid != file.uuid);
    this.setState({ files: files }, () => {
      if (this.props.handleUpdateUploads) {
        this.props.handleUpdateUploads(
          this.props.name,
          this.state.files.map((i) => i.uuid)
        );
      }
    });
  }

  handleDeviceChange(file, e) {
    let value = e.target.value;
    let previousValue = file.device_uuid;
    let files = this.state.files.map((i) => {
      if (i.uuid == file.uuid) {
        return Object.assign(file, { device_uuid: value });
      } else {
        return i;
      }
    });

    this.setState({ files: files }, () => {
      if (
        this.props.master &&
        this.props.master.state.uploadsToSync.find((i) => i.uuid == file.uuid)
      ) {
        this.props.master.setState((state, props) => ({
          uploadsToSync: state.uploadsToSync.map((i) => {
            if (i.uuid == file.uuid) {
              return Object.assign({}, i, { device_uuid: value });
            } else {
              return i;
            }
          }),
        }));
      }
      this.uploadFile(Object.assign(file, { device_uuid: value }));
    });

    // if (previousValue && value != previousValue) {
    //   this.props.removeUploadFromDevice(previousValue, file.uuid)
    // } else {
    //   this.props.addUploadToDevice(value, file.uuid)
    // }
  }

  render() {
    return (
      <div
        className={classNames('upload-container', this.props.helperClass, {
          card: this.props.helperClass != 'in-modal',
        })}
      >
        <div
          className={classNames('card-header', {
            'd-none': this.props.helperClass == 'in-modal',
          })}
        >
          Soubory
        </div>
        <div
          className={classNames('files', {
            'card-body': this.props.helperClass != 'in-modal',
          })}
        >
          {this.state.files.map((i) => {
            return (
              <div
                key={i.uuid}
                className={classNames('file', {
                  'd-none': i.uploaded && this.props.contractId,
                })}
              >
                <div className='preview'>
                  {i.uploaded && i.asset_url ? (
                    <a href={i.asset_url} target='_blank'>
                      {i.content_type && i.content_type.includes('image/') ? (
                        <img src={i.asset_url} alt='' />
                      ) : (
                        <i className='far fa-file'></i>
                      )}
                    </a>
                  ) : (
                    <div className='progress-number nowrap'>
                      {Math.round(i.progress * 100)} %
                    </div>
                  )}
                </div>
                <div className='filename'>{i.filename}</div>
                {i.full_messages && i.full_messages.length > 0 && (
                  <div className='errors text-danger'>
                    {i.full_messages.map((e) => (
                      <div key={e} className='error'>
                        {e}
                      </div>
                    ))}
                  </div>
                )}
                {this.props.devices && (
                  <div
                    className={classNames('device', {
                      'd-none': !(
                        i.content_type && i.content_type.includes('image/')
                      ),
                    })}
                  >
                    <select
                      name='device_uuid'
                      id={`${i.uuid}_device_uuid`}
                      onChange={this.handleDeviceChange.bind(this, i)}
                      value={i.device_uuid || ''}
                      className='form-control'
                    >
                      <option value=''>Připojit k zařízení...</option>
                      {this.props.devices.map((device) => (
                        <option key={device.uuid} value={device.uuid}>
                          {[
                            device.manufacturer,
                            device.type,
                            device.uuid?.split('-')[0],
                          ].join(' | ')}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className='remove'>
                  <a
                    href='#'
                    onClick={this.handleFileRemove.bind(this, i)}
                    className={classNames('text-danger', {
                      'd-none': this.props.contractId,
                    })}
                  >
                    <i className='fas fa-times'></i>
                  </a>
                </div>
              </div>
            );
          })}

          <label
            htmlFor='file-uploader'
            id='file-uploader-label'
            className={classNames('btn btn-secondary', {
              disabled: this.props.editLocked,
            })}
          >
            {this.props.accept && this.props.accept.includes('image/')
              ? 'Připojit fotografie'
              : 'Připojit soubory'}
          </label>
          <input
            disabled={this.props.editLocked}
            type='file'
            className='form-control-file'
            id='file-uploader'
            name='file-uplaoder'
            accept={this.props.accept || null}
            multiple
            onChange={this.handleFileChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Uploader;
