class Request extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <div className="change">
        <div className="body">
          <div className="time">
            <div className="row">
              <div className="col-sm">{moment(this.props.request.created_at).format('LLL')}</div>
              <div className="col-sm">{I18n.t(`request-statuses.${this.props.request.status}`)}</div>
              <div className="col-sm">{I18n.t(`request-kinds.${this.props.request.kind}`)}</div>
              <div className="col-sm">ID {this.props.request.id}</div>
            </div>
          </div>
          <div className="message">{this.props.request.message}</div>
        </div>
        <div className="user">
          <div className="avatar">
            {this.props.user ? (
              <img src={this.props.user.avatar_url} alt=""/>
            ) : null}
          </div>
          <div className="name">{this.props.user ? this.props.user.name : ''}</div>
        </div>
      </div>
    )
  }
}

export default Request
