function Device(props) {
  let attrs = [
    {
      label: 'Výrobce',
      value: props.device.manufacturer
    },{
      label: 'Typ',
      value: props.device.type
    },{
      label: 'Poznámka',
      value: props.device.note
    },{
      label: 'RV',
      value: props.device.year
    },{
      label: 'Verze',
      value: props.device.version
    },{
      label: 'ID',
      value: props.device.uuid?.split('-')[0]
    }
  ]

  attrs = attrs.filter(i => i.value)
  return (
    <div className="device-short">
      {attrs.map(attr =>
        <div className="attribute" key={attr.value}>
          <div className="label">{attr.label}</div>
          <div className="value">{attr.value}</div>
        </div>
      )}
      {props.device.active === false ? (
        <div className="attribute">
          <div className="label">&nbsp;</div>
          <div className="value">
            <strong>
              Neaktivní
            </strong>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Device
