class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // {id: id, started_at: started_at}
      startedContracts: []
    };

    this.modalBody = React.createRef();
  }

  componentDidMount() {
    let modalEl = document.querySelector('#modal')
    if (modalEl && !modalEl.dataset.callbackBinded) {
      jQuery(modalEl).on('show.bs.modal', function (e) {
        history.pushState(Object.assign({modalShow: true}, history.state), null, null)
      })
      // jQuery(modalEl).on('hidden.bs.modal', function (e) {
      //   history.pushState({modalShow: true, turbolinks: false}, null, null)
      // })
      modalEl.dataset.callbackBinded = true
    }

    jQuery('#modal').modal()
  }

  componentDidUpdate() {
    jQuery('#modal').modal()
  }

  handleRefresh(e) {
    e.preventDefault()
    window.location.reload()
  }

  handleContractStart(e) {
    e.preventDefault()
    if (!confirm(`Opravdu zahájit zakázku ${this.props.contract.id}?`)) return true

    let startedAt = new Date().toJSON()
    this.props.master.setState((state, props) => {
      let contracts = state.contracts.map(i => {
        if (i.id == this.props.contract.id) {
          return Object.assign(i, {started_at: startedAt, updated_at: startedAt})
        } else {
          return i
        }
      })

      return {
        contracts: contracts
      }
    })

    this.setState((state, props) => {
      return {
        startedContracts: state.startedContracts.concat({id: this.props.contract.id, started_at: startedAt})
      }
    })

    jQuery.ajax({
      method: 'POST',
      url: '/api/v1/contracts',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({
        contract: {
          id: this.props.contract.id,
          started_at: startedAt
        }
      }),
      beforeSend: (xhr) => {
        if (this.props.master.state.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.master.state.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      success: (data) => {

      },
      error: (data) => {
        if (data.responseJSON && data.responseJSON.contract) {
          if (data.responseJSON.contract.full_messages.length) {
            alert(`Chyba: ${data.responseJSON.contract.full_messages.join(', ')}`)
          } else {
            if (window.Sentry) {
              window.Sentry.captureMessage(JSON.stringify(data.responseJSON))
            }
          }
        } else {
          if (data.responseJSON) {
            if (window.Sentry) {
              window.Sentry.captureMessage(JSON.stringify(data.responseJSON))
            }
          } else {
            if (data.readyState == 0 && this.props.master) {
              let contractsToSync = this.props.master.state.contractsToSync.concat()

              if (contractsToSync.find(i => i.contract.uuid == this.props.contract.uuid)) {
                contractsToSync = contractsToSync.map(i => {
                  if (i.contract.uuid == this.props.contract.uuid) {
                    Object.assign(i.contract, {started_at: startedAt})
                    return i
                  } else {
                    return i
                  }
                })
              } else {
                contractsToSync.push({
                  syncStatus: 'waiting',
                  contract: Object.assign({}, this.props.contract, {started_at: startedAt})
                })
              }

              this.props.master.setState({
                contractsToSync: contractsToSync
              })

              alert('Chyba spojení, data byly uloženy k pozdější synchronizaci.')
            } else {
              alert('Neočekávaná chyba')
            }
          }
        }
      }
    })
  }

  handleSubmitForm(e) {
    e.preventDefault()

    Rails.fire(
      this.modalBody.current.querySelector('form'),
      'submit'
    )
  }

  render() {
    let startedHelper = this.state.startedContracts.find(i => i.id == this.props.contract.id)
    let linkTime
    if (
      this.props.master &&
      this.props.contract &&
      this.props.master.state.user.id == this.props.contract.user_id &&
      this.props.contract.status == 'waiting' &&
      this.props.contract.kind == 'standard'
    ) {
      if (
        !this.props.contract.started_at &&
        !startedHelper &&
        moment(this.props.contract.start_time).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
      ) {
        linkTime = (
          <a href="#" className="btn btn-warning" onClick={this.handleContractStart.bind(this)}>Zahájit</a>
        )
      } else if (startedHelper) {
        linkTime = (
          <a href="#" className="btn btn-warning disabled" disabled>Zahájeno {moment(startedHelper.started_at).format('HH:mm')}</a>
        )
      } else if (this.props.contract.started_at) {
        linkTime = (
          <a href="#" className="btn btn-warning disabled" disabled>Zahájeno {moment(this.props.contract.started_at).format('HH:mm')}</a>
        )
      }
    }

    return (
      <div id="modal" className="modal fade" tabIndex="-1" role="dialog">
        <div
          className={classNames("modal-dialog", {'modal-lg': this.props.size != 'normal'})}
          style={this.props.form ? {maxWidth: '50rem'} : null}
          role="document"
        >
          <div className="modal-content">
            <div className={classNames('modal-header', {'d-none': this.props.headerHidden})}>
              <h5 className="modal-title">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" ref={this.modalBody}>
              {this.props.body}
            </div>
            <div
              className={classNames('modal-footer', {'d-none': this.props.footerHidden})}
              style={{justifyContent: (this.props.form ? 'space-between' : 'flex-end')}}
            >
              <button
                type="button"
                className='btn btn-secondary'
                data-dismiss="modal"
              >
                Zavřít
              </button>
              {linkTime}
              {this.props.createContractLink ? (
                <a href={this.props.createContractLink} className="btn btn-warning">Vytvořit zakázku</a>
              ) : null}
              {this.props.link ? (
                <a href={this.props.link} className="btn btn-primary">{this.props.linkTitle}</a>
              ) : null}
              {this.props.refresh ? (
                <a href="#" className="btn btn-primary" onClick={this.handleRefresh.bind(this)}>{this.props.linkTitle}</a>
              ) : null}
              {this.props.linkEvent ? (
                <a href="#" className="btn btn-primary" onClick={this.props.linkEvent.bind(this)}>{this.props.linkTitle}</a>
              ) : null}
              {this.props.form ? (
                <a href="#" className="btn btn-success pull-right" onClick={this.handleSubmitForm.bind(this)}>Uložit</a>
              ) : null}
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Modal
