import Select from './select'
import ReactSelect, { createFilter } from 'react-select'
import CommonSelect from './common_select'
import CommonAsyncSelect from './common_async_select'
import Signature from './signature'
import CustomerFields from './customer_fields'
import Helper from '../helpers/helper'
import FormErrors from './form_errors'
import Input from './input'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Uploader from './uploader'
import Modal from './modal'
import CustomerModalBody from './customer_modal_body'
import Device from './device'
import QRPayment from './qr_payment'
import DeviceJob from './device_job'
import ConsultationJobs from './consultation_jobs'
import {
  handleEmailPhoneChange,
  addRemoveEmailPhone,
  addDevice,
  removeDevice,
  handleDeviceChange
} from '../helpers/common'

class ContractForm extends React.Component {

  constructor(props) {
    super(props);

    let defaultCustomer = {
      type: 'customer',
      errors: {},
      full_messages: [],
      places: [{uuid: Helper.uuid(), devices: [], type: 'place'}],
      name: (props.customer?.name || ''),
      first_name: (props.customer?.first_name || ''),
      last_name: (props.customer?.last_name || ''),
      title: (props.customer?.title || ''),
      emails: '',
      phones: '',
      street: (props.customer?.street || ''),
      zip: (props.customer?.zip || ''),
      city: (props.customer?.city || ''),
      id_no: (props.customer?.id_no || ''),
      tax_no: (props.customer?.tax_no || ''),
      errors: {},
      full_messages: [],
      routeHelper: '',
      email_status: 'has',
      emails_data: (props.customer?.emails_data || []),
      phones_data: (props.customer?.phones_data || []),
      company: (props.customer?.company || false),
      has_billing_address: (props.customer?.has_billing_address || false),
      billing_name: (props.customer?.billing_name || ''),
      vat_payer: (props.customer?.vat_payer || false),
      place_helper: (props.customer?.place_helper || null),
    }

    if (props.customer?.place_helper) {
      defaultCustomer.places = [props.customer?.place_helper]
    }

    this.defaultCustomer =  JSON.stringify(defaultCustomer)

    let defaultContract = {
      order_id: (props.contract?.order_id || ''),
      create_customer: (props.contract?.create_customer ? true : false),
      edit_customer: ((!props.master && props.contract && props.contract.id && props.contract.kind != 'reservation') || props.contract?.edit_customer ? true : false),
      status: 'waiting',
      start_date: moment().format('YYYY-MM-DD'),
      start_time_helper: moment().format('HH') + ':00',
      duration: 120,
      user_id: (this.props.master ? this.props.master.state.user.id : null),
      user: (this.props.master ? this.props.master.state.user : null),
      type: 'contract',
      errors: {},
      full_messages: [],
      items: [],
      technician_upload_uuids: [],
      operator_upload_uuids: [],
      kind: 'standard',
      action_type: '',
      payment_type: '',
      device_jobs: [],
      deposit_request_date_of_payment: moment().add(14, 'days').format('YYYY-MM-DD'),
      customer_e_agreement: true,
      customer_privacy_agreement: true,
      emails: '',
      phones: '',
      consultation_jobs: {}
    }

    let customer = Object.assign({}, JSON.parse(this.defaultCustomer))
    if (this.props.contract && this.props.contract.id) {
      Object.assign(customer, this.props.contract.customer)
    }

    let contract = (this.props.contract ? Object.assign({}, defaultContract, this.props.contract) : Object.assign({}, defaultContract))
    
    if (props.customer?.place_helper) {
      contract.place_uuid = props.customer.place_helper.uuid
    }

    if (this.props.master && !contract.technician_signature_url) {
      Object.assign(contract, {technician_signature_url: this.props.master.state.user.signature_url})
    }

    this.state = {
      contract: contract,
      customer: customer,
      products: (this.props.master ? this.props.master.state.products : []),
      conflictContracts: [],
      editDevices: false,
      technicianText: 'description',
      tab: 'customer'
    };

    this.currentRequest = null
    this.images = []
  }

  componentDidMount() {
    window.initGoogleMaps()
    window.contractForm = this

    this.fetchProducts()

    // Add default items
    const specialItem = {
      name: 'Nástup technika',
      amount: 1,
      id: Helper.uuid(),
      fixed: true,
      fixedName: true,
      fixedWarranty: true,
      actionTypes: [
        'repair', 'fault_location', 'heating_works', 'shutdown'
      ],
      startDate: '2022-02-21',
      createdAtFrom: '2022-02-18'
    }
    if (!this.state.contract.items.length) {
      let defaultItems = [
        specialItem,
        {name: 'Doprava', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Parkovné', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Výjezd', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Práce', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Měření spalin', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Seřízení analyzátorem', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Ostatní', amount: 1, id: Helper.uuid(), fixed: true, fixedName: true},
        {name: 'Spotřební materiál', amount: '', id: Helper.uuid(), fixed: true}
      ]

      if (this.state.contract.append_items?.length) {
        defaultItems = defaultItems.concat(this.state.contract.append_items)
      }

      this.setState(state => {
        contract: Object.assign(state.contract, {items: defaultItems})
      })
    } else if (!this.state.contract.items.find(i => i.name == 'Nástup technika')) {
      this.setState(state => {
        let items = state.contract.items.concat()
        items.unshift(specialItem)

        return {
          contract: Object.assign(state.contract, {items: items})
        }
      })
    }

    this.fetchConflictContracts()

    // Lock contract
    jQuery.ajax({
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      url: window.helperData.edit_lock_path,
      method: 'POST',
      data: {
        class_name: 'contract',
        id: this.state.contract.id,
        event: 'lock'
      }
    })
  }

  componentWillUnmount() {
    window.contractForm = null

    // Lock contract
    jQuery.ajax({
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      url: window.helperData.edit_lock_path,
      method: 'POST',
      data: {
        class_name: 'contract',
        id: this.state.contract.id,
        event: 'unlock'
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.contract.start_date != this.state.contract.start_date) ||
      (prevState.contract.start_time_helper != this.state.contract.start_time_helper) ||
      (prevState.contract.duration != this.state.contract.duration)
    ) {
      this.fetchConflictContracts()
    }

    if (
      (
        (prevState.contract.start_date != this.state.contract.start_date) ||
        (prevState.contract.start_time_helper != this.state.contract.start_time_helper) ||
        (prevState.contract.user_id != this.state.contract.user_id) ||
        (prevState.latitude != this.state.latitude) ||
        (prevState.longitude != this.state.longitude)
      ) &&
      this.state.contract.start_date &&
      this.state.contract.start_time_helper &&
      this.state.contract.user_id &&
      this.state.latitude &&
      this.state.longitude
    ) {
      this.fetchDirections()
    }

    // PPAS item
    if (
      (
        !prevState.contract.apply_ppas_advantage &&
        this.state.contract.apply_ppas_advantage &&
        this.state.contract.advantage_total_vat
      ) || (
        this.state.contract.apply_ppas_advantage &&
        this.state.contract.advantage_total_vat &&
        prevState.contract.advantage_total_vat != this.state.contract.advantage_total_vat
      ) || (
        this.state.contract.apply_ppas_advantage &&
        prevState.contract.vat_rate != this.state.contract.vat_rate
      ) || (
        prevState.contract.apply_ppas_advantage &&
        !this.state.contract.apply_ppas_advantage
      )
    ) {
      let items = this.state.contract.items.concat()
      items = items.filter(i => !i.ppas)

      if (this.state.contract.apply_ppas_advantage) {
        items.push({
          name: 'Zákaznická výhoda PPAS',
          amount: 1,
          id: Helper.uuid(),
          fixed: true,
          fixedName: true,
          fixedValue: true,
          ppas: true,
          value: -1 * Math.round(this.state.contract.advantage_total_vat / (1 + this.state.contract.vat_rate / 100.0) * 100) / 100
        })
      }

      let contract = Object.assign({}, this.state.contract, {items: items})
      this.setState({contract: contract})
    }

    // Help
    if (prevState.contract.action_type != 'help' && this.state.contract.action_type == 'help') {
      this.setState({moreHidden: true})
    }
    if (prevState.contract.action_type == 'help' && this.state.contract.action_type != 'help') {
      this.setState({moreHidden: false})
    }

    // Has no email logic
    if (this.state.customer && this.state.customer.emails && this.state.customer.has_no_email) {
      this.setState((state, props) => {
        return {
          customer: Object.assign(state.customer, {has_no_email: false})
        }
      })
    }

    // Places coords check
    let skipPlacesCoordsCheck = true
    let places = this.state.customer.places.map(place => {
      let previousPlace = prevState.customer.places.find(i => i.uuid == place.uuid)
      if (
        previousPlace &&
        previousPlace.street != place.street &&
        previousPlace.latitude &&
        previousPlace.longitude &&
        previousPlace.latitude == place.latitude &&
        previousPlace.longitude == place.longitude
      ) {
        skipPlacesCoordsCheck = false
        return Object.assign({}, place, {coordsError: true})
      } else {
        return Object.assign({}, place, {coordsError: false})
      }
    })

    if (!skipPlacesCoordsCheck){
      this.setState((state, props) => {
        return {
          customer: Object.assign({}, state.customer, {places: places})
        }
      })
    }

    // Inflation item
    if (this.applyInflation()) {
      let inflationItem = this.state.contract.items.find(i => i.inflation)
      if (!inflationItem) {
        this.setState((state, props) => {
          let items = state.contract.items.concat()
          items = items.filter(i => !i.inflation)
          items.push({
            name: this.inflationName(),
            amount: 1,
            warranty: '',
            id: Helper.uuid(),
            fixed: true,
            fixedName: true,
            fixedWarranty: true,
            fixedValue: true,
            inflation: true,
            value: this.inflationValue()
          })

          return {
            contract: Object.assign({}, state.contract, {items: items})
          }
        })
      } else {
        // Just update the inflation value & name
        if (
          inflationItem.value != this.inflationValue() &&
          !(
            this.state.contract.status == 'finished' &&
            inflationItem.name == 'Valorizace cen (inflace za r. 2021) 3,80%'
          )
        ) {
          this.setState((state, props) => {
            let items = state.contract.items.map(i => {
              if (i.inflation) {
                return Object.assign({}, i, {
                  value: this.inflationValue(),
                  name: this.inflationName()
                })
              } else {
                return i
              }
            })

            return {
              contract: Object.assign({}, state.contract, {items: items})
            }
          })
        }
      }
    } else if (this.state.contract.items.find(i => i.inflation)) {
      this.setState((state, props) => {
        let items = state.contract.items.filter(i => !i.inflation)

        return {
          contract: Object.assign({}, state.contract, {items: items})
        }
      })
    }

    // Filtering items
    if (
      (this.state.contract.action_type != prevState.contract.action_type) ||
      (this.state.contract.start_date != prevState.contract.start_date)
    ) {
      this.setState((state, props) => {
        let items = state.contract.items.map(i => {
          if (this.hideItem(i)) {
            return Object.assign({}, i, {value: 0})
          } else {
            return i
          }
        })

        return {
          contract: Object.assign({}, state.contract, {items: items})
        }
      })
    }

    // Default technician description
    if (
      this.state.contract.action_type == 'consultation' &&
      prevState.contract.action_type != 'consultation'
    ) {
      this.setState((state, props) => {
        return {
          contract: Object.assign({}, state.contract, {technician_description: 'Konzultace na výměnu spotřebiče'})
        }
      })
    }

    if (
      this.state.contract.action_type == 'consultation_unpaid' &&
      prevState.contract.action_type != 'consultation_unpaid'
    ) {
      this.setState((state, props) => {
        return {
          contract: Object.assign({}, state.contract, {technician_description: 'Konzultace na'})
        }
      })
    }
  }

  fetchProducts() {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/products',
      data: {

      },
      beforeSend: (xhr) => {
        this.setState({loading: true})
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      success: (data) => {
        this.setState({products: data.results})
      },
      complete: () => {
        this.setState({loading: false})
      }
    })
  }

  fetchConflictContracts() {
    if (!this.state.contract.start_date || !this.state.contract.start_time_helper) {
      return true
    }

    let start_time = moment(`${this.state.contract.start_date} ${this.state.contract.start_time_helper}`)
    let end_time = start_time.clone().add(this.state.contract.duration, 'minutes')

    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/conflict-contracts',
      data: {
        start_time: start_time.toISOString(),
        end_time: end_time.toISOString(),
        user_id: this.state.contract.user_id,
        id: this.state.contract.id
      },
      beforeSend: (xhr) => {
        this.setState({loading: true})
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      success: (data) => {
        this.setState((state, props) => ({
          conflictContracts: data.results,
          contract: Object.assign(state.contract, {force_save: false})
        }));
      },
      complete: () => {
        this.setState({loading: false})
      }
    })
  }

  fetchDirections() {
    let arrival_time = moment(`${this.state.contract.start_date} ${this.state.contract.start_time_helper}`)
    if (!this.state.latitude || !this.state.longitude) return true

    jQuery.ajax({
      method: 'POST',
      url: '/api/v1/fetch-directions',
      data: {
        arrival_time: arrival_time.toISOString(),
        user_id: this.state.contract.user_id,
        destination_latlng: [this.state.latitude, this.state.longitude].join(',')
      },
      beforeSend: (xhr) => {
        this.setState({loading: true})
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      success: (data) => {
        if (data && data.google_map_route_url) {
          this.setState({
            route_distance: data.distance,
            route_duration: data.duration,
            google_map_route_url: data.google_map_route_url
          })
        }
      },
      complete: () => {
        this.setState({loading: false})
      }
    })
  }

  handleChangeSelect(name, data) {
    let h = {}
    h[name] = data.value

    this.setState((state, props) => ({
      contract: Object.assign(state.contract, h)
    }));
  }

  handleReactSelectChange(scope, name, data) {
    let h = {}, s = {}
    h[name] = (data ? data.value : null)
    if (name == 'user_id') h.user = data
    if (name == 'customer_id') {
      h.customer = data
      if (data && data.places && data.places.length == 1) {
        h.place_uuid = data.places[0].uuid
        s.latitude = data.places[0].latitude
        s.longitude = data.places[0].longitude
      } else {
        h.place_uuid = null
        s.latitude = null
        s.longitude = null
      }
    }
    if (data && name == 'place_uuid') {
      s.latitude = data.latitude
      s.longitude = data.longitude
    }

    s[scope] = Object.assign({}, this.state[scope], h)
    this.setState(s)
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const contract = Object.assign({}, this.state.contract, {[name]: value})

    if (contract.payment_type == 'ppas' && contract.apply_ppas_advantage) {
      contract.apply_ppas_advantage = false
    }

    this.setState((state, props) => ({
      contract: contract
    }));
  }

  handleCustomerInputChange(e) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state, props) => ({
      customer: Object.assign({}, state.customer, {[name]: value})
    }));
  }

  cancelForm(e) {
    e.preventDefault()
    if (confirm('Opravdu chcete opustit formulář bez uložení?')) {
      window.history.back();
      jQuery.ajax({
        beforeSend: (xhr) => {
          if (this.props.accessToken) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }
        },
        url: window.helperData.edit_lock_path,
        method: 'POST',
        data: {
          class_name: 'contract',
          id: this.state.contract.id,
          event: 'unlock'
        }
      })
    }
  }

  toggleCustomerAction(action, e) {
    e.preventDefault()
    let h = {}
    switch(action) {
      case 'search':
        h = {create_customer: false, edit_customer: false}

        this.setState((state, props) => ({
          contract: Object.assign(state.contract, h)
        }));

        break
      case 'create':
        let places
        if (JSON.parse(this.defaultCustomer).place_helper) {
          places = [JSON.parse(this.defaultCustomer).place_helper]
        } else {
          places = _.cloneDeep(this.state.customer.places).map(i => Object.assign(i, {uuid: Helper.uuid()}))
        }
        
        h = {
          create_customer: true,
          edit_customer: false,
          place_uuid: places[0].uuid
        }

        this.setState((state, props) => ({
          latitude: places[0].latitude,
          longitude: places[0].longitude,
          contract: Object.assign(state.contract, h),
          customer: Object.assign({}, JSON.parse(this.defaultCustomer), {
            places: places,
            street: state.customer.street,
            zip: state.customer.zip,
            city: state.customer.city
          })
        }), () => {
          window.initGoogleMaps()
        });

        break
      case 'edit':
        let place = this.state.contract.customer.places.find(i => i.uuid == this.state.contract.place_uuid) || this.state.contract.customer.places[0]
        h = {
          create_customer: false,
          edit_customer: true,
          place_uuid: (place ? place.uuid : '')
        }

        this.setState((state, props) => ({
          latitude: (place ? place.latitude : ''),
          longitude: (place ? place.longitude : ''),
          contract: Object.assign(state.contract, h),
          customer: Object.assign({}, state.contract.customer)
        }), () => {
          window.initGoogleMaps()
        });

        break
    }
  }

  performContractsToSync() {
    let contractsToSync = this.props.master.state.contractsToSync.concat()

    if (contractsToSync.find(i => i.contract.uuid == this.state.contract.uuid)) {
      contractsToSync = contractsToSync.map(i => {
        if (i.contract.uuid == this.state.contract.uuid) {
          let customer = Object.assign(this.state.customer, {places_attributes: this.state.customer.places})
          return {
            syncStatus: 'waiting',
            contract: Object.assign(this.state.contract, {
              customer: customer,
              sync_at: new Date().toISOString()
            }),
            customer: customer
          }
        } else {
          return i
        }
      })
    } else {
      let customer = Object.assign(this.state.customer, {places_attributes: this.state.customer.places})
      contractsToSync.push({
        syncStatus: 'waiting',
        contract: Object.assign(
          this.state.contract,
          {
            customer: customer,
            sync_at: new Date().toISOString()
          }
        ),
        customer: customer
      })
    }

    // Update contractsToSync & contracts (we assume successful post? we should NOT)
    this.props.master.setState({
      contractsToSync: contractsToSync
      // contracts: this.props.master.state.contracts.map(i => {
      //   if (i.id == this.state.contract.id) {
      //     return this.state.contract
      //   } else {
      //     return i
      //   }
      // })
    })

    alert('Chyba spojení, data byly uloženy k pozdější synchronizaci.')
    window.history.back()
  }

  handleSubmit(status, e) {
    e.preventDefault()

    // Display Modal with commment
    let commentText
    if (status == 'cancelled') {
      commentText = prompt(`Uveďte prosím důvod zrušení ${this.state.contract.kind == 'reservation' ? 'rezervace' : 'zakázky'}:`)
      if (!commentText) {
        return
      }
    }

    if (status == 'finished' && !['true', 'false'].includes(this.state.contract.request_needed)) {
      this.setState((state) => {
        let errors = Object.assign({}, state.contract.errors, {request_needed: ['není vyplněno']})

        const hash = {
          tab: 'requirements',
          contract: Object.assign(
            {},
            state.contract,
            {errors: errors}
          )
        }
        return hash
      })
      alert('Vyplňte prosím, zda je potřeba požadavek')
      return
    }

    let confirmText = 'Opravdu dokončit zakázku?'
    if (this.props.master && !this.state.contract.technician_upload_uuids.length) {
      confirmText = 'POZOR - chybí fotografie. Opravdu dokončit zakázku?'
    }
    if (status == 'finished' && !confirm(confirmText)) return true

    let _this = this

    let newRecord = true
    if (this.state.contract.id) newRecord = false

    // Set start_time & end_time
    let start_time = moment(`${this.state.contract.start_date} ${this.state.contract.start_time_helper}`)
    let end_time = start_time.clone().add(this.state.contract.duration, 'minutes')

    let contract = Object.assign({}, this.state.contract, {
      start_time: start_time.toISOString(),
      end_time: end_time.toISOString(),
      updated_at: new Date().toJSON()
    })

    if (status == 'finished' && !this.state.contract.finished_at) {
      Object.assign(contract, {finished_at: new Date().toJSON()})
    }

    let previousStatus = contract.status
    if (status) contract.status = status

    this.state.contract = contract

    // Offline
    let skipNetwork = false
    if (this.props.master && !this.props.master.state.online) {
      skipNetwork = true
      this.performContractsToSync()
    }

    // Get rid of loaded documents under contract
    if (this.state.contract.customer) {
      Object.assign(this.state.contract.customer, {documents: []})
    }

    if (!skipNetwork) {
      if (commentText) {
        jQuery.ajax({
          method: 'POST',
          url: '/api/v1/comments',
          data: {
            comment: {
              contract_id: this.state.contract.id,
              text: `ZRUŠENO: ${commentText}`
            }
          },
          beforeSend: (xhr) => {
            if (this.props.accessToken) {
              xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
            } else {
              xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
            }
          },
          error: () => {
            alert('Nepodařilo se vytvořit komentář o zrušení')
          }
        })
      }

      const currentRequest = jQuery.ajax({
        method: 'POST',
        url: '/api/v1/contracts',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify({
          contract: this.state.contract,
          customer: Object.assign(this.state.customer, {places_attributes: this.state.customer.places, documents: []}),
          request: {
            sent_at: new Date().toISOString(),
            user_agent: navigator.userAgent
          }
        }),
        beforeSend: (xhr) => {
          if (this.props.accessToken) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }

          if (this.currentRequest) {
            this.currentRequest.abort()
          }
          this.setState({loading: true})
          this.currentRequest = null
        },
        success: (data) => {
          this.state.contract = data
          window.history.back();

          if (this.props.master) {

            // Update contract on master
            if (newRecord) {
              this.props.master.setState((state, props) => ({
                contracts: state.contracts.concat(data)
              }));
            } else {
              let contracts = this.props.master.state.contracts.map(i => {
                if (i.id == data.id) {
                  return data
                } else {
                  return i
                }
              })
              this.props.master.setState({contracts: contracts})
            }

            // Remove from contractsToSync if necessary
            if (this.props.master.state.contractsToSync.find(i => i.contract.uuid == data.uuid)) {
              this.props.master.setState((state, props) => {
                let contractsToSync = state.contractsToSync.filter(i => i.contract.uuid != data.uuid)
                return {
                  contractsToSync: contractsToSync
                }
              });
            }
          }
        },
        error: (data) => {
          if (data.responseJSON && data.responseJSON.contract) {
            let customer = Object.assign({}, this.state.customer, {errors: data.responseJSON.customer.errors, full_messages: data.responseJSON.customer.full_messages})

            customer.places = customer.places.map(i => {
              let place = data.responseJSON.customer.places.find(ii => ii.uuid == i.uuid)
              if (place) {
                return Object.assign(i, {errors: place.errors, full_messages: place.full_messages})
              } else {
                return i
              }
            })

            this.setState({
              contract: Object.assign(
                {},
                this.state.contract,
                {
                  status: previousStatus
                },{
                  errors: data.responseJSON.contract.errors,
                  full_messages: data.responseJSON.contract.full_messages
                }
              ),
              customer: customer
            })

            if (!data.responseJSON.contract.full_messages.length) {
              if (window.Sentry) {
                window.Sentry.captureMessage(JSON.stringify(data.responseJSON))
              }
            }
          } else {
            if (data.responseJSON) {
              if (window.Sentry) {
                window.Sentry.captureMessage(JSON.stringify(data.responseJSON))
              }
            } else {
              if (data.readyState == 0 && this.props.master) {
                _this.performContractsToSync()
              } else {
                alert('Neočekávaná chyba')
              }
            }
          }

          // Scroll to top
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        },
        complete: () => {
          this.setState({loading: false})
          this.currentRequest = null
        }
      })
      this.currentRequest = currentRequest
    }
  }

  handleSignatureChange(name, value) {
    this.setState((state, props) => ({
      // contract: Object.assign(state.contract, {[name]: JSON.stringify(value)})
      contract: Object.assign(state.contract, {[name]: value})
    }));
  }

  loadAres({ e, vatNumber, placeUUID, scenario } = { placeUUID: '', scenario: '' }) {
    Helper.loadAres({
      e,
      component: this,
      vatNumber,
      placeUUID,
      scenario
    })
  }

  addItem(e) {
    e.preventDefault()

    let items = this.state.contract.items.concat()
    let inflationIndex = items.findIndex(i => i.inflation)
    let index = inflationIndex == -1 ? items.length : inflationIndex
    items.splice(index, 0, {
      name: '',
      amount: '',
      warranty: '',
      product_id: '',
      id: Helper.uuid(),
      price: ''
    })

    let contract = Object.assign({}, this.state.contract, {items: items})
    this.setState({contract: contract})
  }

  addTextItem(index, e) {
    e.preventDefault()
    let items = this.state.contract.items.concat()
    items.splice(index+1, 0, {
      textOnly: true,
      name: 'Práce',
      amount: '',
      warranty: '',
      product_id: '',
      id: Helper.uuid(),
      price: ''
    })

    let contract = Object.assign({}, this.state.contract, {items: items})
    this.setState({contract: contract})
  }

  removeItem(id, e) {
    e.preventDefault()
    let items = this.state.contract.items.filter(i => i.id != id)
    let contract = Object.assign({}, this.state.contract, {items: items})
    this.setState({contract: contract})
  }

  handleProductChange(id, column, e) {
    let h
    if (e == null || e.label) {
      if (e) {
        let product = this.state.products.find(i => i.id == e.value)
        h = {name: e.label.replace(' ' + e.ean, ''), ean: e.ean, product_id: (product ? e.value : ''), value: (product ? product.selling_price : 0.0)}
      } else {
        h = {name: '', product_id: ''}
      }

    } else {
      e.preventDefault()
      h = {[e.target.name]: e.target.value}
    }
    let items = this.state.contract[column].map(i => {
      if (i.id == id) {
        return Object.assign({}, i, h)
      } else {
        return i
      }
    })
    let contract = Object.assign({}, this.state.contract, {[column]: items})
    this.setState({contract: contract})
  }

  handleItemChange(id, e) {
    let items = this.state.contract.items.map(i => {
      if (i.id == id) {
        return Object.assign({}, i, {name: e.target.value})
      } else {
        return i
      }
    })

    let contract = Object.assign({}, this.state.contract, { items: items })
    this.setState({contract: contract})
  }

  applyMinus(id, column, e) {
    let skip = false
    let items = this.state.contract[column].map(i => {
      if (i.id == id && i.value) {
        return Object.assign({}, i, {value: i.value * -1})
      } else {
        return i
      }

      if (!i.value) skip = true
    })

    if (!skip) {
      let contract = Object.assign({}, this.state.contract, {[column]: items})
      this.setState({contract: contract})
    }
  }

  handleProductDevicesChange(id, e) {
    let item = this.state.contract.items.find(i => i.id == id)

    if (!item.devices) item.devices = []
    if (e.target.checked) {
      item.devices.push(e.target.value)
    } else {
      item.devices = item.devices.filter(i => i != e.target.value)
    }
    item.devices = _.uniq(item.devices)

    let items = this.state.contract.items.map(i => {
      if (i.id == id) {
        return Object.assign({}, i, item)
      } else {
        return i
      }
    })

    let contract = Object.assign({}, this.state.contract, {items: items})
    this.setState({contract: contract})
  }

  loadProductsOptions(inputValue, callback) {
    let _this = this
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/products',
      data: {q: inputValue},
      beforeSend: (xhr) => {
        this.setState({loading: true})
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }
      },
      success: (data) => {
        let options = data.results.map( i => {
          return Object.assign({value: i.id, label: i.name}, i)
        })
        callback(options)
      },
      complete: () => {
        this.setState({loading: false})
      }
    })
  }

  handleFileChange(e) {
    let _this = this

    Array.from(e.target.files).forEach(file => {
      let reader = new FileReader()

      reader.addEventListener("load", function () { // Setting up base64 URL on image
        // preview.src = reader.result;
        // console.log(reader.result)
        // _this.setState({images: _this.images.concat(reader.result)})
        _this.images.push(reader.result)
        _this.forceUpdate()
      }, false);

      reader.readAsDataURL(file)
    })
  }

  handleUpdateUploads(name, data) {
    let helperData = data
    if (helperData.length == 0) helperData.push('')
    let contract = Object.assign(this.state.contract, {[name]: helperData})
    this.setState({contract: contract})
  }

  handlePlaceInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let latitude = this.state.latitude
    let longitude = this.state.longitude

    let places = this.state.customer.places.map(i => {
      if (i.uuid == this.state.contract.place_uuid) {
        latitude = i.latitude
        longitude = i.longitude
        return Object.assign({}, i, {[name]: value})
      } else {
        return i
      }
    })

    this.setState((state, props) => ({
      latitude: latitude,
      longitude: longitude,
      customer: Object.assign({}, state.customer, {places: places})
    }));
  }

  handleAddPlace(e) {
    e.preventDefault()
    let uuid = Helper.uuid()
    this.setState({
      latitude: null,
      longitude: null,
      customer: Object.assign(
        {},
        this.state.customer, {
          places: this.state.customer.places.concat({uuid: uuid, devices: [], type: 'place'})
        }
      ),
      contract: Object.assign({}, this.state.contract, {place_uuid: uuid})
    })
  }

  handleRemovePlace(uuid, e) {
    e.preventDefault()
    let places = this.state.customer.places.map(i => {
      if (i.uuid == uuid) {
        return Object.assign({}, i, {"_destroy": true})
      } else {
        return i
      }
    })

    let activePlace = places.filter(i => !i._destroy)[0]
    let activeUuid = activePlace.uuid
    let latitude = activePlace.latitude
    let longitude = activePlace.longitude

    this.setState({
      latitude: latitude,
      longitude: longitude,
      customer: Object.assign({}, this.state.customer, {places: places}),
      contract: Object.assign({}, this.state.contract, {place_uuid: activeUuid})
    })
  }

  handlePlaceChange(uuid, latitude, longitude, e) {
    if (e) e.preventDefault()
    this.setState({
      latitude: latitude,
      longitude: longitude,
      contract: Object.assign({}, this.state.contract, {place_uuid: uuid})
    })
  }

  productOption(data) {
    if (!data.isDisabled) {
      if (data.data.name) {
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              <div className="text">
                <div className="primary">{data.data.name}</div>
                <div className="secondary text-muted">{data.data.ean}</div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              {data.data.label}
            </div>
          </div>
        )
      }

    } else {
      return null
    }
  }

  toggleMore(e) {
    e.preventDefault()
    this.setState({moreHidden: !this.state.moreHidden})
  }

  handleDeviceJobCheck(device_job, e) {
    let checked = e.target.checked

    this.setState((state, props) => {
      let job = state.contract.device_jobs.find(i => i.uuid == device_job.uuid)
      if (!job) {
        state.contract.device_jobs.push(device_job)
        job = device_job
      }

      job.checked = checked

      state.contract.device_jobs = state.contract.device_jobs.map(i => {
        if (i.uuid == job.uuid) {
          return job
        } else {
          return i
        }
      })

      return {contract: state.contract}
    })

    return true
  }

  handleInputDeviceChange(uuid, e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state, props) => {
      this.state.contract.device_jobs = this.state.contract.device_jobs.map(i => {
        if (i.uuid == uuid) {
          return Object.assign({}, i, {[name]: value})
        } else {
          return i
        }
      })

      return {
        contract: this.state.contract
      }
    })
  }

  handleConsultationJobInput(e, helper = undefined) {
    let h = {}
    if (helper) {
      h[helper.name] = (e ? e.label : '')
    } else {
      h[e.target.name] = (e.target.type == 'checkbox' ? e.target.checked : e.target.value)
    }
    
    let consultation_jobs = Object.assign({}, this.state.contract.consultation_jobs, h)
    this.setState((state) => {
      return {
        contract: Object.assign({}, state.contract, { consultation_jobs })
      }
    })
  }

  toggleEditDevices(e) {
    if (e) e.preventDefault()
    this.setState((state, props) => ({
      editDevices: !state.editDevices
    }))
  }

  showCustomer(e) {
    e.preventDefault()

    let customer = this.state.contract.customer

    let modalBody = (
      <CustomerModalBody
        master={this.props.master}
        customer={customer}
        accessToken={this.props.accessToken} />
    )

    let component = ReactDOM.render(
      React.createElement(Modal, {
        title: `Detail zákazníka ${customer.id}`,
        body: modalBody
        // linkTitle: 'Upravit',
        // link: `/is/customers/${customer.id}/edit`
      }),
      document.querySelector('#modal-container')
    )
    if (window.mountedComponents) window.mountedComponents.push(component)
  }

  handleDepositPrefill(e) {
    e.preventDefault()
    let text = e.target.textContent
    this.setState((state, props) => {
      return {
        contract: Object.assign(state.contract, {deposit_request_text: text})
      }
    })
  }

  handleFinishedChange(e) {
    let checked = e.target.checked
    this.setState((state, props) => {
      return {
        contract: Object.assign(state.contract, {status: (checked ? 'finished' : 'waiting')})
      }
    })
  }

  handleEmailsPhonesChange(name, data) {
    this.setState((state, props) => {
      return {
        contract: Object.assign(state.contract, {[name]: data?.map(i => i.value).join(',') || ''})
      }
    })
  }

  toggleTechnicianText(name, e) {
    e.preventDefault()
    this.setState({technicianText: name})
  }

  itemsTotal({ withoutInflation } = { withoutInflation: false}) {
    return this.state.contract.items.map(i => {
      if (i.value && (!withoutInflation || !i.inflation)) {
        return (i.amount || 1) * i.value
      } else  {
        return 0
      }
    }).reduce((a, b) => a + b, 0)
  }

  inflationRate() {
    let year
    if (this.state.contract.start_date) {
      year = moment(this.state.contract.start_date).year() - 1
    } else {
      year = moment().year() - 1
    }
    return window.helperData.inflationRates[year.toString()] || window.helperData.inflationRate
  }

  inflationValue() {
    if (this.applyInflation()) {
      return Math.round(this.itemsTotal({withoutInflation: true}) * this.inflationRate() * 100) / 100
    } else {
      return 0.0
    }
  }

  inflationName() {
    if (this.applyInflation()) {
      let year
      if (this.state.contract.start_date) {
        year = moment(this.state.contract.start_date).year() - 1
      } else {
        year = moment().year() - 1
      }

      // Temporary solution
      let name
      if (year >= 2022) {
        name = `Valorizace cen ${Helper.formatMoney(this.inflationRate() * 100)}%`
      } else {
        name = `Valorizace cen (inflace za r. ${year}) ${Helper.formatMoney(this.inflationRate() * 100)}%`
      }

      return name
    } else {
      return ''
    }
  }

  applyInflation() {
    return (
      ['service', 'repair_service'].includes(this.state.contract.action_type) &&
      moment(this.state.contract.created_at || undefined) >= moment('2022-02-18') &&
      (
        !this.state.contract.start_date ||
        moment(this.state.contract.start_date) >= moment('2022-02-21')
      )
    )
  }

  hideItem(i) {
    if (
      (
        i.actionTypes?.length &&
        !i.actionTypes.includes(this.state.contract.action_type)
      ) || (
        i.startDate &&
        this.state.contract.start_date &&
        moment(this.state.contract.start_date) < moment(i.startDate)
      ) || (
        i.createdAtFrom &&
        moment(this.state.contract.created_at) < moment(i.createdAtFrom)
      )
    ) {
      return true
    } else {
      return false
    }
  }

  handleChangeTab(tab, e) {
    e.preventDefault()
    this.setState({tab: tab})
    window.scrollTo(0,0)
  }


  render() {
    let _this = this

    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFromStart: false
    };

    let invalidUser = false
    let place

    if (this.state.contract.user) {
      if (this.state.contract.create_customer || this.state.contract.edit_customer) {
        place = this.state.customer.places.find(i => i.uuid == this.state.contract.place_uuid)
      } else if (this.state.contract.customer && this.state.contract.place_uuid) {
        place = this.state.contract.customer.places.find(i => i.uuid == this.state.contract.place_uuid)
      }
    }

    if (
      place &&
      this.state.contract.device_jobs &&
      this.state.contract.device_jobs.filter(i => i.checked).length
    ) {
      let jobManufacturerIds = place.devices.filter(i => this.state.contract.device_jobs.find(ii => ii.checked && ii.device_uuid == i.uuid)).map(i => i.manufacturer_id)

      for (const id of jobManufacturerIds) {
        if (!this.state.contract.user.manufacturer_ids.includes(id)) {
          invalidUser = true
          break
        }
      }
    }


    let productOptions
    if (this.props.master) {
      productOptions = this.props.master.state.products.map(i => {
        return {label: i.name + ' ' + i.ean, value: i.id, ean: i.ean, name: i.name}
      })
    }

    // Special item
    if (
      this.state.contract.action_type == 'installation'
    ) {
      // Add Revize
      if (!this.state.contract.items.find(i => i.name == 'Revize')) {
        this.state.contract.items.splice(4, 0, {
          name: 'Revize',
          amount: 1,
          warranty: '',
          id: Helper.uuid(),
          fixed: true,
          fixedName: true,
          fixedWarranty: true,
          vatRate: 21.0
        })
      }

      if (!this.state.contract.items.find(i => i.name == 'Cena montáže dle cenové nabídky')) {
        this.state.contract.items.splice(4, 0, {
          name: 'Cena montáže dle cenové nabídky',
          amount: 1,
          warranty: '',
          id: Helper.uuid(),
          fixed: true,
          fixedName: true,
          fixedWarranty: true
        })
      }

      this.state.contract.items = this.state.contract.items.filter(i =>
        !(
          i.fixedName &&
          (!i.value || i.value == 0.0) &&
          [
            'Nástup technika',
            'Doprava',
            'Parkovné',
            'Výjezd',
            'Práce',
            'Měření spalin',
            'Seřízení analyzátorem',
            'Ostatní'
          ].includes(i.name)
        )
      )

    } else if (
      this.state.contract.action_type != 'installation' &&
      this.state.contract.items.find(i => i.name == 'Revize')
    ) {
      // Remove Revize
      this.state.contract.items = this.state.contract.items.filter(i => i.name != 'Revize')
    }

    let itemsTotal = this.itemsTotal()

    let vatRate = this.state.contract.vat_rate
    let vatValue = 0
    vatValue = this.state.contract.items.map(i => {
      let customVatRate = i.vatRate || vatRate
      if (i.value) {
        return (i.amount || 1) * i.value * customVatRate/100
      } else  {
        return 0
      }
    }).reduce((a, b) => a + b, 0)

    if (this.state.contract.reverse_charge) vatValue = 0

    let rounding = 0
    let roundingVat = 0

    if (this.state.contract.payment_type == 'cash') {
      let roundingHelper = Math.round(itemsTotal + vatValue) - (itemsTotal + vatValue)
      rounding = Math.round(roundingHelper / (1 + vatRate/100) * 100) / 100
      roundingVat = roundingHelper - rounding
    }

    let devices = []
    if (this.state.contract.place_uuid) {
      if (this.state.contract.edit_customer || this.state.contract.create_customer) {
        devices = this.state.customer.places.find(i => i.uuid == this.state.contract.place_uuid)?.devices.filter(i => !i.delete) || []
      } else if (this.state.contract.customer) {
        devices = this.state.contract.customer.places.find(i => i.uuid == this.state.contract.place_uuid)?.devices.filter(i => !i.delete) || []
      }
    }

    // Active DeviceJobs
    let active_devices = []
    if (this.state.contract.device_jobs) {
      active_devices = devices.filter(i => this.state.contract.device_jobs.find(ii => ii.checked && ii.device_uuid == i.uuid))
    }

    // Warranty months
    let warranty_months = []
    active_devices.forEach(i => {
      if (i.warranty_until) {
        warranty_months.push(
          moment(i.warranty_until).diff(moment(this.state.contract.start_time).startOf('day'), 'months')
        )
      }
    })

    // Company
    let airConditions = false
    if (
      (
        this.state.contract.company_id &&
        this.props.companies?.find(i => i.id == this.state.contract.company_id)?.air_conditions
      ) || (
        !this.props.companies &&
        this.state.contract.company?.air_conditions
      )
    ) {
      airConditions = true
    }

    let hideDeviceJobs = airConditions || ['heating_works', 'fault_location', 'none', 'consultation'].includes(this.state.contract.action_type)

    let technicianTextFields = (
      <React.Fragment>
        <ul className='nav nav-pills mb-3'>
          <li className="nav-item">
            <a
              className={classNames('nav-link', {'active': this.state.technicianText == 'description'})}
              href="#customer-create"
              onClick={this.toggleTechnicianText.bind(this, 'description')}>
              Popis technika
            </a>
          </li>
          <li className="nav-item">
            <a
              className={classNames('nav-link', {'active': this.state.technicianText == 'comment'})}
              href="#"
              onClick={this.toggleTechnicianText.bind(this, 'comment')}>
              Komentář technika
            </a>
          </li>
        </ul>
        <div className={classNames({'d-none': this.state.technicianText != 'description'})}>
          <Input
            name="technician_description"
            type="textarea"
            object={this.state.contract}
            onChange={this.handleInputChange.bind(this)}
            label={'false'}
            placeholder="Popis technika"
          />
        </div>
        <div className={classNames({'d-none': this.state.technicianText != 'comment'})}>
          <Input
            name="technician_comment"
            type="textarea"
            object={this.state.contract}
            onChange={this.handleInputChange.bind(this)}
            label={'false'}
            placeholder="Komentář technika"
          />
        </div>
      </React.Fragment>
    )

    // Emails & Phones data
    let emailsData = []
    let phonesData = []
    if (
      this.state.contract.edit_customer ||
      this.state.contract.create_customer
    ) {
      emailsData = this.state.customer.emails_data
      phonesData = this.state.customer.phones_data
    } else if (this.state.contract.customer) {
      emailsData = this.state.contract.customer.emails_data
      phonesData = this.state.contract.customer.phones_data
    }

    // Installation Types
    let installationTypesOptions = window.helperData.contract.installation_details.concat()
    if (
      this.state.contract.installation_detail &&
      !installationTypesOptions.includes(this.state.contract.installation_detail)
    ) {
      installationTypesOptions.unshift({
        value: this.state.contract.installation_detail,
        label: this.state.contract.installation_detail
      })
    }
    installationTypesOptions.unshift({
      value: '',
      label: ''
    })

    // To Pay
    let toPay = itemsTotal + rounding + vatValue + roundingVat - (this.state.contract.apply_proforma ? this.state.contract.apply_proforma.value : 0) - (this.state.contract.partial_payment ? this.state.contract.partial_payment_value : 0)

    let showDefaultPrices = false && ['service', 'repair', 'repair_service', 'launch_unpaid', 'heating_works', 'flue_inspection_report'].includes(this.state.contract.action_type)

    // Available Vat Rates
    let vatRatesDecisionDate = new Date()
    if (this.state.contract.start_date) {
      vatRatesDecisionDate = new Date(this.state.contract.start_date)
    }
    const availableVatRates = window.helperData.vat_rates.filter(i => {
      return new Date(i.from) <= vatRatesDecisionDate && (!i.to || new Date(i.to) >= vatRatesDecisionDate)
    })

    // Available Payment Types
    let availablePaymentTypes = window.helperData.contract.payment_types
    if (this.state.contract.action_type == 'installation' && this.state.contract.payment_type != 'card') {
      availablePaymentTypes = availablePaymentTypes.filter(i => i.value != 'card')
    }

    return (
      <div className="medium-wrapper">
        {this.state.contract.contract_id ? (
          <p><em>Navazuje na zakázku {this.state.contract.contract_id}</em></p>
        ) : null}
        <form>
          <FormErrors errors={this.state.contract.full_messages} />

          <div className="contract-form-nav mb-4">
            <ul className="nav nav-pills">
              <li className="nav-item" style={{order: 10}}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'customer')}
                  className={classNames('nav-link', {'active': this.state.tab == 'customer'})}>
                  Zákazník
                </a>
              </li>
              <li className="nav-item" style={{order: 20}}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'basic')}
                  className={classNames('nav-link', {'active': this.state.tab == 'basic'})}>
                  Základní
                </a>
              </li>
              <li className="nav-item" style={{order: 30}}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'jobs')}
                  className={classNames('nav-link', {'active': this.state.tab == 'jobs'})}>
                  Provedené práce
                </a>
              </li>
              <li className="nav-item" style={{order: 40}}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'requirements')}
                  className={classNames('nav-link', {'active': this.state.tab == 'requirements'})}>
                  Požadavky
                </a>
              </li>
              <li className="nav-item" style={{order: 50}}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'uploads')}
                  className={classNames('nav-link', {'active': this.state.tab == 'uploads'})}>
                  Soubory
                </a>
              </li>
              <li className="nav-item" style={{ order: (!this.props.master ? 35 : 60) }}>
                <a
                  href="#"
                  onClick={this.handleChangeTab.bind(this, 'invoicing')}
                  className={classNames('nav-link', {'active': this.state.tab == 'invoicing'})}>
                  Fakturace
                </a>
              </li>
            </ul>
          </div>

          <div className={classNames({'d-none': this.state.tab != 'customer'})}>
            <div className={classNames('well', {'d-none-off': this.state.contract.kind == 'reservation'})}>
              <div className='card bg-light mb-4'>
                <div className='card-header'>
                  <nav>
                    <ul className='nav nav-pills contract-customer'>
                      <li className='nav-item'>
                        <a className="nav-link disabled special" href="#">Zákazník</a>
                      </li>
                      <li className={classNames('nav-item', {'d-none': !this.state.contract.customer || !this.state.contract.customer.id})}>
                        {this.state.contract.edit_customer && this.props.master ? (
                          <a
                            className="nav-link"
                            href="#customer-search"
                            onClick={this.toggleCustomerAction.bind(this, 'search')}>
                            Zrušit
                          </a>
                        ) : (
                          <a
                            className={classNames('nav-link', {'active': this.state.contract.edit_customer})}
                            href="#customer-edit"
                            onClick={this.toggleCustomerAction.bind(this, 'edit')}>
                            Upravit
                          </a>
                        )}
                      </li>
                      <li className={classNames('nav-item', {'d-none': (this.props.master && this.state.contract.id) || this.state.contract.contract_id})}>
                        <a
                          className={classNames('nav-link', {'active': !this.state.contract.create_customer && !this.state.contract.edit_customer})}
                          href="#customer-search"
                          onClick={this.toggleCustomerAction.bind(this, 'search')}>
                          Vyhledat
                        </a>
                      </li>
                      <li className={classNames('nav-item', {'d-none': (this.props.master && this.state.contract.id) || this.state.contract.customer_id})}>
                        <a
                          className={classNames('nav-link', {'active': this.state.contract.create_customer})}
                          href="#customer-create"
                          onClick={this.toggleCustomerAction.bind(this, 'create')}>
                          Vytvořit
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className='card-body form-bottom'>
                  <div className={classNames('tab-pane', {'d-none': this.state.contract.create_customer || this.state.contract.edit_customer})} id='customer-search'>
                    <div className="form-group">
                      <CommonAsyncSelect
                        name="id"
                        value={this.state.contract.customer}
                        url="/api/v1/customers"
                        type="customer"
                        errors={[]}
                        accessToken={this.props.accessToken}
                        handleReactSelectChange={this.handleReactSelectChange.bind(this, 'contract', 'customer_id')}
                        disabled={this.state.contract.id && (this.state.contract.contract_id || this.props.master) ? true : false}
                        />
                    </div>
                    {this.state.contract.customer ? (
                      <div style={{marginTop: '-1rem', marginBottom: '1rem'}}>
                        <small>
                          <a href="#" onClick={this.showCustomer.bind(this)}>detail zákazníka</a>
                        </small>
                      </div>
                    ) : null}
                    {this.state.contract.customer_id ? (
                      <div className="form-group">
                        <CommonSelect
                          name="place_uuid"
                          value={this.state.contract.place_uuid ? (this.state.contract.customer.places.filter(i => i.uuid == this.state.contract.place_uuid)[0] || null) : null}
                          options={this.state.contract.customer ? this.state.contract.customer.places : []}
                          type="place"
                          errors={this.state.contract.errors['place_uuid'] || []}
                          placeholder="Místo"
                          handleChange={this.handleReactSelectChange.bind(this, 'contract', 'place_uuid')}
                          disabled={this.state.contract.contract_id || (this.props.master && this.state.contract.id) ? true : false}
                          />
                      </div>
                    ) : null}

                  </div>
                  <div className={classNames('tab-pane', {'d-none': !this.state.contract.create_customer && !this.state.contract.edit_customer})} id='customer-create'>
                    <CustomerFields
                      customer={this.state.customer}
                      handleInputChange={this.handleCustomerInputChange.bind(this)}
                      handleReactSelectChange={this.handleReactSelectChange.bind(this)}
                      accessToken={this.props.accessToken}
                      loadAres={this.loadAres.bind(this)}
                      loadingAres={this.state.loadingAres}
                      master={this.props.master}
                      handleEmailPhoneChange={handleEmailPhoneChange.bind(this)}
                      addRemoveEmailPhone={addRemoveEmailPhone.bind(this)}
                      addDevice={addDevice.bind(this)}
                      removeDevice={removeDevice.bind(this)}
                      handleDeviceChange={handleDeviceChange.bind(this)}
                      activePlaceUUID={this.state.contract.place_uuid}
                      handlePlaceInputChange={this.handlePlaceInputChange.bind(this)}
                      handleAddPlace={this.handleAddPlace.bind(this)}
                      handleRemovePlace={this.handleRemovePlace.bind(this)}
                      handlePlaceChange={this.handlePlaceChange.bind(this)} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames({'d-none': this.state.tab != 'basic'})}>
            <div className="row">
              {this.props.companies && this.props.companies.length ? (
                <div className="col">
                  <Select
                    name="company_id"
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                    options={this.props.companies}
                  />
                </div>
              ) : null}

              {!this.state.contract.id ? (
                <div className="col">
                  <Select
                    name="kind"
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                    options={window.helperData.contract.kinds}
                    label={false}
                    disabled={this.state.contract.contract_id ? true : false}
                  />
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="" className="form-control-label">E-mail</label>
                    <ReactSelect
                      isMulti
                      value={(this.state.contract.emails || '').split(',').map(i => {
                        if (i) {
                          let emailData = emailsData.find(ii => ii.value == i)
                          return {
                            value: i,
                            label: `${i} ${emailData ? emailData.note : ''}`
                          }
                        }
                      })}
                      onChange={this.handleEmailsPhonesChange.bind(this, 'emails')}
                      options={emailsData.map(i => {
                        return {
                          value: i.value, label: `${i.value} ${i.note}`
                        }
                      })}
                      placeholder={emailsData.length > 1 ? emailsData.filter(i => i.default).map(i => `${i.value} ${i.note}`).join(', ') : emailsData.map(i => `${i.value} ${i.note}`).join(', ')}
                      noOptionsMessage={function() { return 'Nic nenalezeno'}}
                      isClearable={true}
                    />
                </div>
              </div>

              <div className={classNames('col-sm-6', {'d-none': this.props.master && this.props.master.state.user && !this.props.master.state.user.can_see_phones})}>
                <div className="form-group">
                  <label htmlFor="" className="form-control-label">Telefon</label>
                    <ReactSelect
                      isMulti
                      value={(this.state.contract.phones || '').split(',').map(i => {
                        if (i) {
                          let phoneData = phonesData.find(ii => ii.value == i)
                          return {
                            value: i,
                            label: `${i} ${phoneData ? phoneData.note : ''}`
                          }
                        }
                      })}
                      onChange={this.handleEmailsPhonesChange.bind(this, 'phones')}
                      options={phonesData.map(i => {
                        return {
                          value: i.value, label: `${i.value} ${i.note}`
                        }
                      })}
                      placeholder={phonesData.length > 1 ? phonesData.filter(i => i.default).map(i => `${i.value} ${i.note}`).join(', ') : phonesData.map(i => `${i.value} ${i.note}`).join(', ')}
                      noOptionsMessage={function() { return 'Nic nenalezeno'}}
                      isClearable={true}
                    />
                </div>
              </div>
            </div>

            {this.state.contract.customer ? (
              <p className={classNames('invalid-feedback', {'force-display': this.state.contract.customer.kind == 'problematic'})}>
                <strong>Upozornění</strong>: problémový zákazník. {this.state.contract.customer.problematic_note}
              </p>
            ) : null}

            <div className={classNames('devices mb-4', {'d-none': this.props.master && this.state.contract.id})}>
              <div className="mb-2">
                <strong>Zařízení zakázky</strong>
              </div>
              {devices.length && this.state.contract.device_jobs ? (
                <React.Fragment>
                  {devices.map(device => {
                    let device_job = this.state.contract.device_jobs.find(i => i.device_uuid == device.uuid) || {
                      checked: false,
                      uuid: Helper.uuid(),
                      device_uuid: device.uuid
                    }

                    // Remember current device
                    Object.assign(device_job, {device: device})

                    return (
                      <div className="form-group" key={device.uuid}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id={device.uuid}
                            type="checkbox"
                            checked={device_job.checked}
                            onChange={this.handleDeviceJobCheck.bind(this, device_job)} />
                          <label htmlFor={device.uuid}>
                            <Device device={device} />
                          </label>
                        </div>
                      </div>
                    )
                  })}
                  <p className={classNames('invalid-feedback', {'force-display': !this.state.contract.device_jobs.length})}>
                    <strong>Upozornění</strong>: není vybrané zařízení
                  </p>
                </React.Fragment>
              ) : (
                <em>Žádné zařízení zatím není k výběru</em>
              )}
            </div>

            <div className={classNames({'d-none': this.props.master})}>
              <Select
                name="action_type"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
                options={window.helperData.contract.action_types}
              />
            </div>

            <div className={classNames('form-group', {'d-none': this.props.accessToken})}>
              <label htmlFor="" className="form-control-label">Technik</label>
              <CommonAsyncSelect
                name="user_id"
                value={this.state.contract.user}
                url="/api/v1/users?rights=technician"
                type="user"
                errors={this.state.contract.errors['user_id'] || []}
                accessToken={this.props.accessToken}
                handleReactSelectChange={this.handleReactSelectChange.bind(this, 'contract', 'user_id')}
                />
              <div className={classNames('invalid-feedback', {'force-display': invalidUser})}>
                <strong>Upozornění</strong>: nekompatibilní technik / zákazník
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Input
                  name="start_date"
                  type="date"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />
              </div>
              <div className='col-sm-auto'>
                <Input
                  name="start_time_helper"
                  type="time"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />
              </div>
              <div className='col-sm-2'>
                <Input
                  name="duration"
                  type="number"
                  step="1"
                  hint="v minutách"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />
              </div>
              <div className={classNames('col-auto', {'d-none': this.props.master})}>
                <label className="form-control-label" style={{display: 'block'}}>&nbsp;</label>
                <a
                  href="/is/planner"
                  className="btn btn-secondary"
                  target="_blank">
                  Plánovač
                  <i className="fas fa-external-link-square-alt" style={{marginLeft: '1rem'}}></i>
                </a>
              </div>
            </div>

            {(
              !this.props.master &&
              ['superadmin', 'accountant'].includes(document.body.dataset.currentUserRights) &&
              this.state.contract.status == 'finished'
            ) ? (
              <div className="row">
                <div className="col-sm-4">
                  <Input
                    name="started_at"
                    type="datetime-local"
                    value={this.state.contract.started_at ? moment(this.state.contract.started_at).format('YYYY-MM-DDTHH:mm') : ''}
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className="col-sm-4">
                  <Input
                    name="finished_at"
                    type="datetime-local"
                    value={this.state.contract.finished_at ? moment(this.state.contract.finished_at).format('YYYY-MM-DDTHH:mm') : ''}
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
              </div>
            ) : null}

            {this.state.conflictContracts.length ? (
              <React.Fragment>
                <p className={classNames('invalid-feedback', {'force-display': true})}>
                  <strong>Upozornění</strong>: Zvolený čas se překrývá s jinými zakázkami
                </p>
                <Input
                  name="force_save"
                  label="Automaticky upravit čas předchozí zakázky"
                  type="checkbox"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />
              </React.Fragment>
            ) : null}

            {this.state.google_map_route_url && this.state.latitude && this.state.longitude && this.state.contract.start_date && this.state.contract.start_time_helper && this.state.contract.user_id ? (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <span className="text-muted">Čas:</span>&nbsp;{Helper.timeFormatted(this.state.route_duration)}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <span className="text-muted">Vzdálenost:</span>&nbsp;{Math.round(this.state.route_distance/100)/10}&nbsp;km
                  </li>
                  <li className="breadcrumb-item" aria-current="page"><a href={this.state.google_map_route_url} target="_blank">Zobrazit na mapě</a></li>
                </ol>
              </nav>
            ) : null}

            <Input
              name="note"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            {showDefaultPrices && (
              <div className="row">
                {['entry', 'job', 'transport', 'analyzator', 'material'].map(i =>
                  <div className={classNames('col', {
                      'd-none': (i == 'entry' && !['repair', 'fault_location', 'heating_works', 'shutdown'].includes(this.state.contract.action_type))
                    })} key={i}>
                    <Input
                      disabled={this.props.master}
                      name={`default_${i}_price`}
                      type="number"
                      object={this.state.contract}
                      onChange={this.handleInputChange.bind(this)}
                    />
                  </div>
                )}
              </div>
            )}

            {this.state.contract.action_type == 'help' ? (
              <Select
                name="action_type"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
                options={window.helperData.contract.action_types}
              />
            ) : null}

            {(
              this.props.master &&
              (this.state.contract.kind == 'reservation' ||
              this.state.contract.action_type == 'help')
            ) ? (
              technicianTextFields
            ) : null}

            {this.state.contract.kind != 'reservation' && this.state.contract.action_type != 'help' ? (
              <div className="row">
                <div className="col">
                  <Input
                    name="express"
                    type="checkbox"
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className={classNames('col', {'d-none': true || !(this.state.contract.express && showDefaultPrices)})}>
                  <Input
                    label="false"
                    disabled={this.props.master}
                    name="default_express_price"
                    type="number"
                    placeholder="Výjezd"
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            ) : null}

            {(
              this.state.contract.kind != 'reservation' &&
              !this.props.master &&
              ['superadmin', 'accountant'].includes(document.body.dataset.currentUserRights)
            ) ? (
              <React.Fragment>
                <Input
                  name="deposit_request"
                  type="checkbox"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />

                <div className={classNames({'d-none': !this.state.contract.deposit_request})}>
                  <div className="row">
                    <div className="col">
                      <Input
                        name="deposit_request_value"
                        type="number"
                        object={this.state.contract}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>
                    <div className="col">
                      <Select
                        name="deposit_request_payment_type"
                        object={this.state.contract}
                        onChange={this.handleInputChange.bind(this)}
                        options={window.helperData.contract.payment_types.filter( i => !['ppas', 'other'].includes(i.value)).reverse()}
                      />
                    </div>
                    <div className="col">
                      <Input
                        name="deposit_request_date_of_payment"
                        type="date"
                        object={this.state.contract}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="form-control-label" htmlFor="deposit_request_text">
                        {I18n.t('activerecord.attributes.contract.deposit_request_text')}
                      </label>
                    </div>
                    <div className="col text-right">
                      <div className="dropdown mb-1">
                        <button
                          className="btn btn-sm btn-outline-secondary dropdown-toggle"
                          data-toggle="dropdown">
                          Předvyplnit
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a
                            href="#"
                            className="dropdown-item"
                            onClick={this.handleDepositPrefill.bind(this)}>
                            Fakturujeme Vám zálohu na montáž kotle viz nabídka
                          </a>
                          <a
                            href="#"
                            className="dropdown-item"
                            onClick={this.handleDepositPrefill.bind(this)}>
                            Fakturujeme Vám zálohu na
                          </a>
                          <a
                            href="#"
                            className="dropdown-item"
                            onClick={this.handleDepositPrefill.bind(this)}>
                            Fakturujeme Vám zálohu na servisní prohlídku plynového kotle
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Input
                    label={"false"}
                    name="deposit_request_text"
                    type="textarea"
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
              </React.Fragment>
            ) : null}
          </div>

          <div className={classNames({'d-none': this.state.tab != 'jobs'})}>
            <div className="row">
              <div className="col">
                <Select
                  name="action_type"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                  options={window.helperData.contract.action_types}
                />
              </div>
              <div className={classNames('col', {'d-none': this.state.contract.action_type != 'installation'})}>
                <Select
                  name="installation_detail"
                  placeholder="Prosím vyberte"
                  options={installationTypesOptions}
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />
              </div>
            </div>

            <Input
              name="customer_unavailable"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <div className={classNames({'d-none': hideDeviceJobs})}>
              <div className="row">
                <div className="col">
                  <h5>Provedené práce</h5>
                </div>
                <div className="col-md-auto">
                  <a href="#" onClick={this.toggleEditDevices.bind(this)}>
                    vybrat zařízení zakázky
                  </a>
                </div>
              </div>

              <p className={classNames('invalid-feedback', {'force-display': !this.state.contract.device_jobs.filter(i => i.checked).length})}>
                <strong>Upozornění</strong>: není vybrané zařízení
              </p>

              <div className={classNames('devices mb-4', {'d-none': !this.state.editDevices})}>
                {devices.length && this.state.contract.device_jobs ? (
                  devices.map(device => {
                    let device_job = this.state.contract.device_jobs.find(i => i.device_uuid == device.uuid) || {checked: false, uuid: Helper.uuid(), device_uuid: device.uuid}

                    return (
                      <div className="form-group" key={device.uuid}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id={device.uuid + '-tech'}
                            type="checkbox"
                            checked={device_job.checked}
                            onChange={this.handleDeviceJobCheck.bind(this, device_job)} />
                          <label htmlFor={device.uuid + '-tech'}>
                            <Device device={device} />
                          </label>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <em>Žádné zařízení zatím není k výběru.</em>
                )}
              </div>

              {devices.map(device =>
                <DeviceJob
                  key={device.uuid}
                  contract={this.state.contract}
                  device={device}
                  handleInputDeviceChange={this.handleInputDeviceChange.bind(this)} />
              )}
            </div>

            {['consultation'].includes(this.state.contract.action_type) && (
              <ConsultationJobs
                master={this.props.master}
                accessToken={this.props.accessToken}
                contract={this.state.contract}
                handleInputChange={this.handleConsultationJobInput.bind(this)} />
            )}

            {technicianTextFields}
          </div>

          <div className={classNames({'d-none': this.state.tab != 'requirements'})}>
            <Select
              name="request_needed"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              options={[{value: '', label: ''}, {value: 'true', label: 'Ano'}, {value: 'false', label: 'Ne'}]}
            />

            <Input
              name="operator_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="operator_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.operator_request})}
              label="false"
              placeholder="Popis požadavku na dispečink"
            />

            <Input
              name="consultation_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="consultation_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.consultation_request})}
              label="false"
              placeholder="Popis požadavku na konzultaci"
            />

            <Input
              name="product_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="product_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.product_request})}
              label="false"
              placeholder="Popis požadavku na díl"
            />

            <Input
              name="part_rental_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="part_rental_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.part_rental_request})}
              label="false"
              placeholder="Zapůjčení dílu"
            />

            <Input
              name="installation_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="installation_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.installation_request})}
              label="false"
              placeholder="Popis požadavku na topenářské práce"
            />

            <Input
              name="proforma_request"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="proforma_request_description"
              type="textarea"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              className={classNames({'d-none': !this.state.contract.proforma_request})}
              label="false"
              placeholder="Popis požadavku na zálohu"
            />

            <Input
              name="future_deposit_request"
              type="checkbox"
              disabled={this.state.contract.future_deposit_request_locked}
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <div className={classNames({'d-none': !this.state.contract.future_deposit_request})}>
              <div className="row">
                <div className="col-sm-6">
                  <Input
                    name="future_deposit_request_value"
                    type="number"
                    disabled={this.state.contract.future_deposit_request_locked}
                    object={this.state.contract}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className="col-sm-6">
                  <Select
                    name="future_deposit_request_payment_type"
                    object={this.state.contract}
                    disabled={this.state.contract.future_deposit_request_locked}
                    onChange={this.handleInputChange.bind(this)}
                    options={window.helperData.contract.payment_types.filter( i => !['ppas', 'other', 'transfer', 'card'].includes(i.value))}
                  />
                </div>
              </div>
              <Input
                name="future_deposit_request_text"
                type="textarea"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />

              {this.state.contract.future_deposit_request && this.state.contract.future_deposit_request_payment_type == 'qr' &&
                <QRPayment
                  toPay={(this.state.contract.future_deposit_request_value ? parseFloat(this.state.contract.future_deposit_request_value) : 0.0)}
                  contract={this.state.contract}
                  master={this.props.master} />
              }
            </div>
          </div>

          <div className={classNames({'d-none': this.state.tab != 'uploads'})}>
            { !this.props.master ? (
              <React.Fragment>
                <Uploader
                  handleUpdateUploads={this.handleUpdateUploads.bind(this)}
                  name="operator_upload_uuids"
                  files={this.state.contract.operator_upload_files}
                  devices={active_devices} />
              </React.Fragment>
            ) : null}

            {
              this.props.master ? (
                <Uploader
                  handleUpdateUploads={this.handleUpdateUploads.bind(this)}
                  name="technician_upload_uuids"
                  accept="image/*"
                  files={this.state.contract.technician_upload_files}
                  master={this.props.master}
                  devices={active_devices} />
              ) : null
            }
          </div>

          <div className={classNames({'d-none': this.state.tab != 'invoicing'})}>
            <p className="text-muted">
              <em>
                Popis: {this.state.contract.description}
              </em>
            </p>

            <div className='card bg-light mb-4'>
              <div className='card-header'>Položky fakturace</div>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{width: '40%'}}>Položka</th>
                    <th style={{width: '12%'}}>ZD</th>
                    <th style={{width: '12%'}}>Množství</th>
                    <th>Cena bez DPH</th>
                    <th>Celkem</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.contract.items.map((i, index) => {
                    let product
                    let warning = false
                    let inventory = 0
                    if (i.product_id) {
                      product = this.state.products.find(ii => ii.id == i.product_id)
                      if (
                        product &&
                        this.state.contract.user_id &&
                        (
                          !product.users_inventory[this.state.contract.user_id.toString()] ||
                          product.users_inventory[this.state.contract.user_id.toString()] < i.amount
                        )
                      ) {
                        warning = true
                        if (product.users_inventory[this.state.contract.user_id.toString()]) {
                          inventory = product.users_inventory[this.state.contract.user_id.toString()]
                        }
                      }
                    }

                    return (
                      <React.Fragment key={i.id}>
                        <tr className={classNames({'d-none': this.hideItem(i)})}>
                          <td>
                            {i.fixedName || i.textOnly ? (
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={i.vatRate ? `${i.name} - DPH ${i.vatRate}%` : i.name}
                                  readOnly={i.fixedName}
                                  onChange={this.handleItemChange.bind(this, i.id)}
                                  className="form-control" />
                                {i.fixedName && i.name == 'Práce' && (
                                  <div className="input-group-append">
                                    <button
                                      onClick={this.addTextItem.bind(this, index)}
                                      className="btn btn-outline-secondary"
                                      type="button">
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              this.props.master ? (
                                <CreatableSelect
                                  defaultOptions
                                  defaultValue={i.name ? {label: i.name, value: i.product_id, name: i.name, ean: i.ean} : null}
                                  placeholder="Položka"
                                  isClearable={true}
                                  options={productOptions}
                                  onChange={this.handleProductChange.bind(this, i.id, 'items')}
                                  formatCreateLabel={(i) => `Neznámý: ${i}`}
                                  components={{ Option: _this.productOption.bind(this), SingleValue: _this.productOption.bind(this) }}
                                />
                              ) : (
                                <AsyncCreatableSelect
                                  defaultOptions
                                  defaultValue={i.name ? {label: i.name, value: i.product_id, name: i.name, ean: i.ean} : null}
                                  placeholder="Položka"
                                  isClearable={true}
                                  loadOptions={this.loadProductsOptions.bind(this)}
                                  onChange={this.handleProductChange.bind(this, i.id, 'items')}
                                  formatCreateLabel={(i) => `Neznámý: ${i}`}
                                  components={{ Option: _this.productOption.bind(this), SingleValue: _this.productOption.bind(this) }}
                                />
                              )
                            )}

                            <p className={classNames('invalid-feedback', {'force-display': warning && this.props.contract.id})}>
                              <strong>Upozornění</strong>: vydávate více než máte zaevidováno na skladě: <strong>{inventory}</strong>
                            </p>
                          </td>
                          <td>
                            <input
                              type="number"
                              placeholder="ZD"
                              className="form-control"
                              name="warranty"
                              value={i.warranty}
                              readOnly={i.fixedValue || i.fixedWarranty || ['Doprava', 'Parkovné', 'Výjezd', 'Měření spalin', 'Seřízení analyzátorem', 'Ostatní',].includes(i.name)}
                              onChange={this.handleProductChange.bind(this, i.id, 'items')} />
                          </td>
                          <td>
                            <input
                              type="number"
                              placeholder="Množství"
                              className="form-control"
                              name="amount"
                              value={i.amount}
                              readOnly={i.fixedValue}
                              onChange={this.handleProductChange.bind(this, i.id, 'items')} />
                          </td>
                          <td>
                            <div className="value-wrapper">
                              <input
                                type="number"
                                placeholder="Cena"
                                className="form-control"
                                name="value"
                                value={i.value}
                                readOnly={i.fixedValue}
                                onChange={this.handleProductChange.bind(this, i.id, 'items')} />
                              <i className="fas fa-minus-circle" onClick={this.applyMinus.bind(this, i.id, 'items')}></i>
                            </div>
                          </td>
                          <td className="text-right nowrap special-item-total">
                            {i.value ? Helper.formatMoney(i.value * (i.amount || 1)) : 0}
                          </td>
                          <td>
                            <a href="#" className={classNames("btn btn-outline-secondary", {'d-none': i.fixed})} onClick={this.removeItem.bind(this, i.id)}>
                              <i className="fas fa-times"></i>
                            </a>
                          </td>
                        </tr>
                        {active_devices.length > 1 && !['Doprava', 'Parkovné', 'Výjezd', 'Ostatní'].includes(i.name) ? (
                          <tr className="item-devices">
                            <td colSpan="5">
                              {active_devices.map(device =>
                                <div className="form-group" key={device.uuid}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      id={`item_${i.id}_device_${device.uuid}`}
                                      value={device.uuid}
                                      type="checkbox"
                                      checked={i.devices && i.devices.includes(device.uuid)}
                                      onChange={this.handleProductDevicesChange.bind(this, i.id)} />
                                    <label htmlFor={`item_${i.id}_device_${device.uuid}`}>
                                      <Device device={device} />
                                    </label>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    )
                  })}


                  <tr className={classNames({'d-none': this.state.contract.payment_type != 'cash'})}>
                    <td>
                      <a href="#" className="btn btn-secondary" onClick={this.addItem.bind(this)}>
                        Přidat položku
                      </a>
                    </td>
                    <td colSpan="3" className="text-right align-middle">Zaokrouhlení</td>
                    <td className="text-right nowrap align-middle">{Helper.formatMoney(rounding)}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="#"
                        className={classNames('btn btn-secondary', {'d-none': this.state.contract.payment_type == 'cash'})}
                        onClick={this.addItem.bind(this)}>
                        Přidat položku
                      </a>
                    </td>
                    <td colSpan="3" className="text-right align-middle">Celkem bez DPH</td>
                    <td className="text-right nowrap align-middle">{Helper.formatMoney(itemsTotal + rounding)}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right align-middle">
                      <div className="vat-selection">
                        <div className="label">
                          DPH
                        </div>
                        <div className="select">
                          <Select
                            name="vat_rate"
                            label="false"
                            object={this.state.contract}
                            onChange={this.handleInputChange.bind(this)}
                            options={availableVatRates}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="text-right nowrap align-middle">{Helper.formatMoney(vatValue + roundingVat)}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan="4" className="text-right align-middle">Celkem vč. DPH</th>
                    <th className="text-right nowrap align-middle">{Helper.formatMoney(itemsTotal + rounding + vatValue + roundingVat)}</th>
                    <td></td>
                  </tr>
                  {this.state.contract.apply_proforma || this.state.contract.partial_payment ? (
                    <React.Fragment>
                      {this.state.contract.apply_proforma ? (
                        <tr>
                          <th colSpan="4" className="text-right align-middle">Záloha č. {this.state.contract.apply_proforma.number}</th>
                          <th className="text-right nowrap align-middle">{Helper.formatMoney(this.state.contract.apply_proforma.value * -1)}</th>
                          <td></td>
                        </tr>
                      ) : null}
                      {this.state.contract.partial_payment ? (
                        <tr>
                          <th colSpan="4" className="text-right align-middle">Částečná úhrada hotově</th>
                          <th className="text-right nowrap align-middle">{Helper.formatMoney(this.state.contract.partial_payment_value * -1)}</th>
                          <td></td>
                        </tr>
                      ) : null}
                      <tr>
                        <th colSpan="4" className="text-right align-middle">Celkem k úhradě</th>
                      <th className="text-right nowrap align-middle">{Helper.formatMoney(toPay)}</th>
                        <td></td>
                      </tr>
                    </React.Fragment>
                  ) : null}

                </tbody>
              </table>
              <div className="card-body" style={{paddingTop: 0}}>
                {this.state.contract.action_type == 'installation' && (
                  <>
                    {warranty_months.length > 0 && (
                      <Input
                        name="helper_1"
                        label={I18n.t('installation-warranty', {
                          count: warranty_months.join(' a '),
                          months: I18n.t('month', {count: warranty_months.slice(-1)[0]})
                        })}
                        checkboxInvisible={true}
                        type="checkbox"
                        object={this.state.contract}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    )}

                    <Input
                      name="helper_2"
                      label={I18n.t('installation-paperwork')}
                      checkboxInvisible={true}
                      type="checkbox"
                      object={this.state.contract}
                      onChange={this.handleInputChange.bind(this)}
                    />
                  </>
                )}

                <Input
                  name="lower_vat"
                  type="checkbox"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />

                <Input
                  name="reverse_charge"
                  type="checkbox"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />

                <Input
                  name="customer_e_agreement"
                  type="checkbox"
                  object={this.state.contract}
                  onChange={this.handleInputChange.bind(this)}
                />

              {(
                this.state.customer &&
                !this.state.customer.emails &&
                (
                  this.state.contract.edit_customer ||
                  this.state.contract.create_customer
                )
              ) || (
                this.state.contract &&
                this.state.contract.customer &&
                !this.state.contract.customer.emails &&
                !this.state.contract.edit_customer &&
                !this.state.contract.create_customer
              ) ? (
                <div className="text-danger">Chybí email zákazníka</div>
              ) : null}
              </div>
            </div>

            <Select
              name="payment_type"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
              options={availablePaymentTypes}
            />

            <div className={classNames({'d-none': this.state.contract.payment_type != 'ppas'})}>
              <Input
                name="ppas_partner_number"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                name="ppas_meter_number"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                name="ppas_consumption"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
            </div>

            <Input
              name="partial_payment"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <div className={classNames({'d-none': !this.state.contract.partial_payment})}>
              <Input
                name="partial_payment_value"
                type="number"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
            </div>

            <Input
              name="do_not_send_email"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="force_worksheet"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="apply_ppas_advantage"
              type="checkbox"
              object={this.state.contract}
              disabled={this.state.contract.payment_type == 'ppas'}
              onChange={this.handleInputChange.bind(this)}
            />

            <div className={classNames({'d-none': !this.state.contract.apply_ppas_advantage})}>
              <Input
                name="advantage_total_vat"
                type="number"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                name="advantage_ppas_partner_number"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                name="advantage_ppas_meter_number"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                name="advantage_ppas_consumption"
                type="text"
                object={this.state.contract}
                onChange={this.handleInputChange.bind(this)}
              />
            </div>



            <div className='card bg-light mb-4'>
              <div className='card-body'>
                <div className='row text-center'>
                  <div className='col-sm-6'>
                    <Signature
                      value={this.state.contract.technician_signature_url}
                      name="technician_sign"
                      handleSignatureChange={this.handleSignatureChange.bind(this)} />
                    podpis servisního technika
                  </div>
                  <div className='col-sm-6'>
                    <Signature
                      value={this.state.contract.customer_signature_url}
                      name="customer_sign"
                      handleSignatureChange={this.handleSignatureChange.bind(this)} />
                    podpis zákazníka
                  </div>
                </div>
              </div>
            </div>

            <Input
              name="customer_privacy_agreement"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="has_ppas"
              type="checkbox"
              id="has_ppas_contract"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <Input
              name="promo_clima"
              type="checkbox"
              object={this.state.contract}
              onChange={this.handleInputChange.bind(this)}
            />

            <p className={classNames('invalid-feedback', {'force-display': (this.state.customer.email_status == 'missing')})}>
              <strong>Upozornění</strong>: doplňte e-mail zákazníka.
            </p>

            {(
              this.props.master &&
              this.props.master.state.user.id == this.state.contract.user_id &&
              ((moment(this.state.contract.finished_at) >= moment().add(-30, 'minutes') && this.state.contract.status == 'finished'))
            ) ? (
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.state.contract.status == 'finished'}
                  id="finished"
                  onChange={this.handleFinishedChange.bind(this)} />
                <label className="form-check-label" htmlFor="finished">
                  Dokončeno
                </label>
              </div>
            ) : null}

            {this.state.contract.payment_type == 'qr' &&
              <QRPayment
                toPay={toPay}
                contract={this.state.contract}
                master={this.props.master} />
            }
          </div>

          <hr className="mt-4 mb-4 "/>
          <div className="form-actions">
            <div className="left">
              <a href="#" className="btn btn-lg btn-secondary" onClick={this.cancelForm.bind(this)}>Zpět</a>
              {this.state.contract.id && !this.props.master && this.state.contract.status != 'finished' ? (
                <a
                  href="#"
                  style={{marginLeft: '1rem'}}
                  className="btn btn-lg btn-outline-danger"
                  onClick={this.handleSubmit.bind(this, 'cancelled')}>
                  Zrušit {this.state.contract.kind == 'reservation' ? 'rezervaci' : 'zakázku'}
                </a>
              ) : null}
            </div>
            <div className="right">
              <button
                type="submit"
                className="btn btn-lg btn-success"
                disabled={this.state.loading}
                onClick={this.handleSubmit.bind(this, null)}>
                Uložit
              </button>

              {
                (this.state.contract.id || moment(this.state.contract.start_date) < moment().startOf('day')) &&
                (this.props.rights != 'operator' || (this.props.rights == 'operator' && moment(this.state.contract.start_date) < moment().startOf('day'))) &&
                !['finished','cancelled'].includes(this.state.contract.status) &&
                this.state.contract.kind != 'reservation'
              ? (
                <a
                  href="#"
                  style={{marginLeft: '1rem'}}
                  className="btn btn-lg btn-warning"
                  disabled={this.state.loading}
                  onClick={this.handleSubmit.bind(this, 'finished')}>
                  Dokončit
                </a>
              ) : null}

              { !this.props.master && ['request_operator', 'request_inventory'].includes(this.state.contract.status) ? (
                <a
                  href="#" style={{marginLeft: '1rem'}}
                  className="btn btn-lg btn-success"
                  disabled={this.state.loading}
                  onClick={this.handleSubmit.bind(this, 'waiting')}>
                  Předat technikovi
                </a>
                ) : null}

            </div>
          </div>
        </form>

        { /*window.environment == 'development' ? (
          <pre className="mt-4 pre-scrollable">
            {JSON.stringify(this.state, null, 2)}
          </pre>
        ) : null */}

      </div>
    )
  }
}

export default ContractForm
