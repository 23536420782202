import AsyncSelect from 'react-select/async'
import Helper from '../helpers/helper'

// const Option = props => {
//   const { innerProps, innerRef } = props;
//   console.log(innerProps)
//   console.log(props)
//   return (
//     <article ref={innerRef} {...innerProps} className={'custom-option'}>
//       <h4>{props.data.artist}</h4>
//       <div className={'sub'}>{props.data.title}</div>
//     </article>
//   );
// };

class CommonAsyncSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      defaultValue: '',
      loading: false
    };
    this.currentRequest = null
  }

  componentDidMount() {
  }

  fetchOptions() {
    let _this = this

    fetch(this.props.url)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        _this.setState({options: data.results});
      });
  }

  loadOptions(inputValue, callback) {
    let _this = this
    if (this.currentTimeout) clearTimeout(this.currentTimeout)
    if (this.currentRequest) {
      this.currentRequest.abort()
    }
    this.currentTimeout = setTimeout(() => {
      const currentRequest = jQuery.ajax({
        method: 'GET',
        url: _this.props.url,
        data: {q: inputValue},
        beforeSend: (xhr) => {
          if (this.currentRequest) {
            this.currentRequest.abort()
          }
          this.setState({loading: true})
          this.currentRequest = null
          if (this.props.accessToken) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }
        },
        success: (data) => {
          let options = data.results.map( i => {
            return Object.assign({value: i.id, label: i.name}, i)
          })
          callback(options)
        },
        complete: () => {
          this.setState({loading: false})
          this.currentRequest = null
        }
      })
      this.currentRequest = currentRequest
    }, 500)
  }

  submitFilter(a,b) {
    setTimeout(() => {
      let form = ReactDOM.findDOMNode(this).closest('form')
      if (form.dataset.remote) {
        Rails.fire(form, 'submit')
      } else {
        form.submit()
      }
    }, 100)
  }

  customOption(data) {
    switch (this.props.type) {
      case 'user':
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              <div className="avatar">
                <img src={data.data.avatar_url} />
              </div>
              <div className="text">
                <div className="primary">{data.data.label}</div>
                <div className="secondary text-muted">{data.data.manufacturer_names}</div>
              </div>
            </div>
          </div>
        )
        break

      case 'customer':
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              <div className="text">
                <div className="primary">{data.data.label}</div>
                { data.data.label ? (
                  <div className="secondary text-muted">
                    E-mail: {data.data.emails ? data.data.emails.split(',').join(', ') : null}
                    <br/>
                    Tel.: {data.data.phones ? data.data.phones.split(',').join(', ') : null}
                    <br/>
                    Počet míst: {data.data.places.length}
                    <br/>
                    Poslední cena dopravy: {data.data.last_transport_cost ? Helper.formatMoney(data.data.last_transport_cost) : '-'}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
        break

        case 'customer_simple':
          return (
            <div ref={data.innerRef} {...data.innerProps}>
              <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
                <div className="text">
                  <div className="primary">{data.data.label}</div>
                  { data.data.label ? (
                    <div className="secondary text-muted">
                      ID: {data.data.id}
                      <br />
                      E-mail: {data.data.emails ? data.data.emails.split(',').join(', ') : null}
                      <br/>
                      Tel.: {data.data.phones ? data.data.phones.split(',').join(', ') : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
          break

      case 'product':
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              <div className="text">
                <div className="primary">{data.data.name}</div>
                <div className="secondary text-muted">{data.data.ean}</div>
              </div>
            </div>
          </div>
        )
        break

      default:
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className="option-custom padded">{data.data.label}</div>
          </div>
        )
    }
  }

  render() {
    let _this = this

    let customStyles = {}
    if (!this.props.multi) {
      customStyles = {
        valueContainer: (provided, state) => ({
          ...provided,
          flexWrap: 'nowrap'
        })
      }
    }
    return (
      <div className={classNames({'is-invalid': this.props.errors.length})}>
        <AsyncSelect
          styles={customStyles}
          name={this.props.name}
          isMulti={this.props.multi}
          defaultValue={this.props.value}
          loadOptions={this.loadOptions.bind(this)}
          defaultOptions={true}
          placeholder={this.props.placeholder || 'Hledat'}
          noOptionsMessage={function() { return 'Nic nenalezeno'}}
          components={{ Option: _this.customOption.bind(this), SingleValue: _this.customOption.bind(this) }}
          isClearable={true}
          onChange={this.props.handleReactSelectChange ? this.props.handleReactSelectChange.bind(this) : (this.props.filter ? this.submitFilter.bind(this) : null)}
          isDisabled={this.props.disabled}
        />
        {this.props.errors.length ? (
          <div className="invalid-feedback" style={{display: 'block'}}>{this.props.errors[0]}</div>
        ) : null}
      </div>
    )
  }
}

export default CommonAsyncSelect
