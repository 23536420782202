import ContractStatus from './contract_status'

class ContractList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <table className={classNames("table table-striped", {'d-none': this.props.loading || this.props.contracts.length == 0})}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Datum a čas</th>
            <th>Doba (min)</th>
            <th>Technik</th>
            <th>{I18n.t('activerecord.attributes.contract.action_type')}</th>
            <th>{I18n.t('activerecord.attributes.contract.status')}</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {this.props.contracts.map(contract => {
            let userHelper = this.props.users.filter(u => u.id == contract.user_id)[0]

            return (
              <tr key={contract.id}>
                <td>{contract.id}</td>
                <td>{moment(contract.start_time).format('DD.MM.YY HH:mm')}</td>
                <td>{contract.duration}</td>
                <td>
                  {userHelper ? (
                    <React.Fragment>
                      <img src={userHelper.avatar_url} className="tiny-avatar" alt=""/>
                      <span>{userHelper.name}</span>
                    </React.Fragment>
                  ) : null}
                </td>
                <td>
                  {I18n.t(`action-types.${contract.action_type}`)}
                </td>
                <td>
                  {contract.kind == 'reservation' ? (
                    <em>Rezervace</em>
                  ) : (
                    <React.Fragment>
                      <ContractStatus status={contract.status} />
                      {contract.requests.filter(r => r.status == 'opened').length ? (
                        <span className="badge badge-danger ml-1">{contract.requests.filter(r => r.status == 'opened').length}</span>
                      ) : null}
                    </React.Fragment>
                  )}

                </td>
                <td className="text-center">
                  <a href="#" className="btn btn-sm btn-primary" onClick={this.props.showContract.bind(this, contract, 'basic')}>Detail</a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}

export default ContractList
