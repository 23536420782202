

class Select extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let options = Object.assign([], this.props.options)

    // Include blank?
    if (!this.props.object[this.props.name] && !options.find(o => o.value === '')) {
      options.unshift({
        value: '',
        label: ''
      })
    }

    return (
      <div className="form-group">
        {this.props.label == "false" ? null : (
          <label htmlFor={this.props.name}>{this.props.label || I18n.t(`activerecord.attributes.${this.props.object.type}.${this.props.name}`, {defaultValue: I18n.t(`attributes.${this.props.name}`)})}</label>
        )}
        <div className="input-group">
          <select
            name={this.props.name}
            id={this.props.name}
            onChange={this.props.onChange}
            value={this.props.object[this.props.name] || ''}
            className={classNames('form-control', this.props.className, {'is-invalid': this.props.object.errors && this.props.object.errors[this.props.name] && this.props.object.errors[this.props.name].length})}
            disabled={this.props.disabled}>

            {options.map((i, index) =>
              <option key={index} value={i.value}>{i.label}</option>
            )}
          </select>

          {this.props.append && (
            <div className='input-group-append'>
              <div className='input-group-text'>{this.props.append}</div>
            </div>
          )}
        </div>

        {this.props.hint ? (
          <small className="form-text text-muted">{this.props.hint}</small>
        ) : null}

        <div className="invalid-feedback">{this.props.object.errors && this.props.object.errors[this.props.name] && this.props.object.errors[this.props.name].length ? this.props.object.errors[this.props.name][0] : null}</div>
      </div>
    )
  }
}

export default Select
