class ContractStatus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <span className={classNames('badge', {
            'badge-success': this.props.status == 'finished',
            'badge-warning': ['request_operator', 'request_inventory', 'waiting_inventory'].includes(this.props.status),
            'badge-info': this.props.status == 'waiting',
            'badge-secondary': this.props.status == 'cancelled'
          })}>{I18n.t('statuses.'+this.props.status)}</span>

        {this.props.status == 'request_operator' && this.props.message ? (
          <React.Fragment>
            <br/>
            <small>{this.props.message}</small>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}

export default ContractStatus
