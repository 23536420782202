import Loading from '../loading'
import Helper from '../../helpers/helper'

class Inventory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: ''
    }
  }

  handleSearch(e) {
    this.setState({
      search: e.target.value
    })
  }

  render() {
    if (!this.props.master.state.products) {
      return (
        <Loading />
      )
    }

    let products = this.props.master.state.products.filter(i =>
      i.users_inventory[this.props.master.state.user.id.toString()] > 0
    )
    if (this.state.search) {
      let query = this.state.search.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      let re = new RegExp(query, 'i')
      products = products.filter(i => (i.name + ' ' + i.ean).search(re) >= 0)
    }

    return (
      <React.Fragment>
        <div className="card bg-light">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <input
                  placeholder="Hledat"
                  className="form-control"
                  type="text"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearch.bind(this)} />
              </div>
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Název a kód</th>
                <th>Ks</th>
                <th className="text-right">Prodejní cena</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product =>
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>
                    {product.name}
                    <br/>
                    <small className="text-muted">{product.ean}</small>
                  </td>
                  <td>{product.users_inventory[this.props.master.state.user.id.toString()]}</td>
                  <td className="text-right">{Helper.formatMoney(product.selling_price)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

export default Inventory
