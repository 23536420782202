
import SignaturePad from 'signature_pad'

class Signature extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      signature: '',
      value: (this.props.value ? this.props.value : ''),
      locked: true,
      padHeight: (window.innerWidth - 40) / 2
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locked == true && this.state.locked == false) {
      let _this = this
      let canvas = document.querySelector(`#signature_${this.props.name.replace(/\[|\]/g,"")}`)
      let signature = new SignaturePad(canvas, {
        onEnd: (e) => {
          _this.setState({value: signature.toDataURL("image/svg+xml")})
          // _this.setState({value: signature.toData()})
          if (this.props.handleSignatureChange) {
            // this.props.handleSignatureChange(this.props.name, signature.toData())
            this.props.handleSignatureChange(this.props.name, signature.toDataURL("image/svg+xml"))
          }
        }
      })

      // if (this.state.value) signature.fromData(this.state.value)
      // if (this.props.value && this.props.value.length) {
      //   signature.fromDataURL(this.props.value)
      // }

      window.signature = signature
      this.setState({signature: signature})

      let resizeCanvas = () => {
        this.tmpData = window.signature.toData()
        this.setState({padHeight: (window.innerWidth - 40) / 2}, () => {
          // High DPI
          let ratio =  Math.max((window.devicePixelRatio || 1), 1)
          // let canvasElement = this.refs.canvas
          canvas.width = canvas.offsetWidth * ratio
          canvas.height = canvas.offsetHeight * ratio
          canvas.getContext("2d").scale(ratio, ratio)
          signature.clear()
          if (this.tmpData) signature.fromData(this.tmpData)
        })
      }

      window.onresize = resizeCanvas;
      resizeCanvas()
    }
  }

  clearSignature(e) {
    if (e) e.preventDefault()
    this.state.signature.clear()
    this.setState({value: ''})

    if (this.props.handleSignatureChange) {
      // this.props.handleSignatureChange(this.props.name, [])
      this.props.handleSignatureChange(this.props.name, '')
    }
  }

  openPad(e) {
    e.preventDefault()
    this.setState({locked: false})
  }

  closePad(e) {
    e.preventDefault()
    this.setState({locked: true})
  }

  render() {
    return (
      <div>
        <div>
          <a href="#" onClick={this.openPad.bind(this)} className="btn btn-outline-secondary mb-3">Podepsat</a>
        </div>

        {this.state.value ? (
          <img src={this.state.value} className={classNames('signature-image', {'d-none': false})} alt="" />
        ) : null}

        <div className={classNames('signature-pad-container', {'d-none': this.state.locked})}>
          <div className="card bg-light">
            <div className="card-body">
              <div className="signature-pad-wrapper" style={{height: this.state.padHeight}}>
                <canvas ref="canvas" className="signature-pad" id={`signature_${this.props.name.replace(/\[|\]/g,"")}`}></canvas>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <a href="#" onClick={this.clearSignature.bind(this)} className="btn btn-outline-danger">Vyčistit</a>
                </div>
                <div className="col">
                  <a href="#" onClick={this.closePad.bind(this)} className="btn btn-success">OK</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" value={this.state.value} name={this.props.name}/>
      </div>
    )
  }
}

export default Signature
