import Helper from '../helpers/helper'

class Contract extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillUnmount() {
    if (this.mapTimer) {
      clearTimeout(this.mapTimer)
    }
  }

  render() {
    let start_time = moment(this.props.contract.planner_start_time)
    let end_time = moment(this.props.contract.planner_end_time)
    let top = ((start_time.hours()*60 + start_time.minutes())/60-7)*Helper.hourHeight+'rem'

    let bottom
    if (end_time.hours() < start_time.hours()) {
      bottom = 0
    } else {
      bottom = (17 * Helper.hourHeight - ((end_time.hours() + end_time.minutes()/60 - 7) * Helper.hourHeight)) + 'rem'
    }

    let helperClass
    if (this.props.contract.requests && this.props.contract.requests.filter(i => i.status == 'opened').length) helperClass = 'has-opened-requests'

    return (
      <div
        className={classNames(
          'contract',
          this.props.contract.status,
          this.props.contract.kind,
          this.props.contract.action_type,
          helperClass,
          {
            'express': this.props.contract.express,
            'customer-unavailable': this.props.contract.customer_unavailable,
            'just-started': (this.props.contract.started_at && this.props.contract.status == 'waiting'),
            'launch': (['launch', 'launch_unpaid'].includes(this.props.contract.action_type))
          }
        )}
        key={this.props.contract.id}
        style={{top: top, bottom: bottom}}
        onClick={this.props.showContract.bind(this, this.props.contract)}
        onMouseEnter={this.props.toggleMap.bind(this, {lat: parseFloat(this.props.contract.place?.latitude), lng: parseFloat(this.props.contract.place?.longitude)} ,'show')}
        onMouseLeave={this.props.toggleMap.bind(this, {}, 'hide')}>

        <div className="contract-inner">
          {this.props.operator ? (
            <div className={classNames('other-payment text-danger', {'d-none': this.props.contract.payment_type != 'other'})}>
              <i className="fas fa-ban"></i>
            </div>
          ) : null}
          <div className="top">
            <div className="id">
              {this.props.contract.id}
            </div>
            <div className="time">
              {start_time.format('HH:mm')} - {end_time.format('HH:mm')}
            </div>
            <div className="route">
              {Helper.timeFormatted(this.props.contract.route_duration)} / {Math.round(this.props.contract.route_distance/100)/10}&nbsp;km
            </div>
            {this.props.contract.action_type && this.props.contract.kind != 'reservation' && (
              <div className="action-type">
                {I18n.t(`action-types.${this.props.contract.action_type}`)}
              </div>
            )}
          </div>
          {this.props.contract.action_type == 'missing_contract' && (
            <div className="note">
              Chybějící zakázka
            </div>
          )}
          <div className={classNames('note', {'d-none': !this.props.contract.note})}>{this.props.contract.note}</div>
          <div className="customer">
            {this.props.contract.customer ? this.props.contract.customer.name : null}
          </div>
          {this.props.contract.place ? (
            <div className="address">
              {this.props.contract.place.street}, {this.props.contract.place.city}, {this.props.contract.place.zip}
            </div>
          ) : null}
          <div className="description">
            {this.props.contract.description}
          </div>
          {this.props.contract.place ? (
            <div className="devices">
              {(this.props.contract.device_jobs || []).filter(i => i.checked).map(device_job => {
                let device = this.props.contract.place.devices.find(i => i.uuid == device_job.device_uuid)
                if (device) {
                  return (
                    <div key={device_job.device_uuid}>{[[device.manufacturer, device.type].join(': '), device.note].filter(i => i).join(' - ')}</div>
                  )
                } else {
                  return (
                    <div key={device_job.device_uuid}></div>
                  )
                }

              })}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Contract
