import Loading from '../loading'

class TimeStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      start_date: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      end_date: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      data: {}
    };
    this.currentRequest = null
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    if (this.currentRequest) this.currentRequest.abort()
  }

  fetchData(silent=false) {
    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/time-stats',
      data: {
        filter: {
          start_date: this.state.start_date,
          end_date: this.state.end_date
        }
      },
      beforeSend: (xhr) => {
        if (!silent) this.setState({loading: true})
        if (this.props.accessToken && this.props.accessToken.access_token) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        }
        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.currentRequest = null
      },
      success: (data) => {
        this.setState({
          data: data,
          start_date: data.start_date,
          end_date: data.end_date
        })
      },
      complete: () => {
        if (!silent) this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.fetchData()
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="card bg-light">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <input
                  className="form-control"
                  type="date"
                  name="start_date"
                  value={this.state.start_date}
                  onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="date"
                  name="end_date"
                  value={this.state.end_date}
                  onChange={this.handleChange.bind(this)} />
              </div>
            </div>
          </div>
        </div>

        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="table-wrapper" style={{marginTop: '1rem'}}>
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th>Celkem zakázek</th>
                    <td>{this.state.data.total}</td>
                  </tr>
                  <tr>
                    <th>Celkem zakázek s údajem zahájeno</th>
                    <td>{this.state.data.total_with_start}</td>
                  </tr>
                  <tr>
                    <th>Čas plánovaný <sup>*</sup></th>
                    <td>{this.state.data.time_planned_formatted}</td>
                  </tr>
                  <tr>
                    <th>Čas skutečný <sup>*</sup></th>
                    <td>{this.state.data.time_real_formatted}</td>
                  </tr>
                  <tr>
                    <th>Rozdíl <sup>*</sup></th>
                    <td>
                      <strong className={classNames({
                          'text-success': this.state.data.diff >= 0,
                          'text-danger': this.state.data.diff < 0
                        })}>
                        {this.state.data.diff_formatted}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th>Zahájeno předčasně <sup>*</sup></th>
                    <td>{this.state.data.early_started}</td>
                  </tr>
                  <tr>
                    <th>Zahájeno v čas (+/- 15 minut) <sup>*</sup></th>
                    <td>{this.state.data.ontime_started}</td>
                  </tr>
                  <tr>
                    <th>Zahájeno pozdě <sup>*</sup></th>
                    <td>{this.state.data.lately_started}</td>
                  </tr>
                  <tr>
                    <th>Ukončeno předčasně</th>
                    <td>{this.state.data.early_finished}</td>
                  </tr>
                  <tr>
                    <th>Ukončeno v čas (+/- 15 minut)</th>
                    <td>{this.state.data.ontime_finished}</td>
                  </tr>
                  <tr>
                    <th>Ukončeno pozdě</th>
                    <td>{this.state.data.lately_finished}</td>
                  </tr>
                </tbody>
              </table>

              <em>* počítá se pouze ze zakázek, u kterých je evidován čas zahájení</em>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default TimeStats
