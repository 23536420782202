import Loading from './loading'
import DocumentList from './document_list'
import Helper from '../helpers/helper'
import ContractModalBody from './contract_modal_body'
import Modal from './modal'
import Select from './select'
// import SyncStatus from './sync_status'

class Stats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      start_date: '',
      end_date: '',
      to_pay: 0.0,
      owed: 0.0,
      documents: [],
      payment_type: 'cash'
    };
    this.currentRequest = null
  }

  componentDidMount() {
    this.fetchDocuments()
  }

  componentWillUnmount() {
    if (this.currentRequest) this.currentRequest.abort()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date != this.state.start_date ||
      prevState.end_date != this.state.end_date ||
      prevState.payment_type != this.state.payment_type
    ) {
      this.fetchDocuments()
    }
  }

  fetchDocuments(silent=false) {
    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/documents',
      data: {
        user_ids: [this.props.accessToken.user_id],
        payment_type: this.state.payment_type,
        start_date: this.state.start_date,
        end_date: this.state.end_date
      },
      beforeSend: (xhr) => {
        if (!silent) this.setState({loading: true})
        if (this.props.accessToken && this.props.accessToken.access_token) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token);
        }
        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.currentRequest = null
      },
      success: (data) => {
        this.setState({
          documents: data.results,
          to_pay: data.to_pay,
          total_vat: data.total_vat,
          total: data.total,
          owed: data.owed,
          withdrawn_cash: data.withdrawn_cash
        })
      },
      complete: () => {
        if (!silent) this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  showContract(contract, e) {
    e.preventDefault()
    e.stopPropagation()

    const showModal = (contract) => {
      let modalBody = (
        <ContractModalBody
          contract={contract}
          master={this.props.master}
          accessToken={this.props.accessToken} />
      )

      // LinkEvent
      let linkEvent
      if (this.props.master) {
        if (
          this.props.master.state.user.id == contract.user_id &&
          ((moment(contract.finished_at) >= moment().add(-30, 'minutes') && contract.status == 'finished') ||
          contract.status != 'finished')
        ) {
          linkEvent = (e) => {
            e.preventDefault()
            jQuery('#modal').modal('hide')
            this.props.master.setState({page: 'contract-form', editContract: contract})
            history.pushState({page: 'contract-form'}, null, 'contract-form')
          }
        } else {
          linkEvent = null
        }
      } else {
        linkEvent = (e) => {
          e.preventDefault()
          Turbolinks.visit(`/is/contracts/${contract.id}/edit`)
        }
      }

      ReactDOM.render(
        React.createElement(Modal, {
          title: `Detail ${contract.kind == 'reservation' ? 'rezervace' : 'zakázky'} ${contract.id}`,
          body: modalBody,
          linkTitle: 'Upravit',
          linkEvent: linkEvent,
          contract: contract,
          master: this.props.master
          // link: `/is/contracts/${contract.id}/edit`
        }),
        document.querySelector('#modal-container')
      )
    }

    // Fetch contract if only id passed
    if (Number.isInteger(contract)) {
      let contract_id = contract
      fetch(`/api/v1/contracts/${contract_id}`, {
        headers: new Headers({
         'Authorization': `Bearer ${this.props.accessToken.access_token}`
       })
      }).then((response) => {
          return response.json();
        })
        .then((data) => {
          showModal(data)
        });
    } else {
      showModal(contract)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="card bg-light">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <input
                  className="form-control"
                  type="date"
                  name="start_date"
                  value={this.state.start_date}
                  onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col-sm-4">
                <input
                  className="form-control"
                  type="date"
                  name="end_date"
                  value={this.state.end_date}
                  onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  name="payment_type"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.payment_type}>

                  {window.helperData.contract.payment_types.map(i =>
                    <option key={i.value} value={i.value}>{i.label}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>

        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <p style={{marginTop: '1rem'}}>
              <strong>
                Vybraná hotovost: {Helper.formatMoney(this.state.withdrawn_cash)}
              </strong>
            </p>


            <div className="table-wrapper">
              <DocumentList
                documents={this.state.documents}
                contractView={true}
                pdfView={false}
                showContract={this.showContract.bind(this)}/>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default Stats
