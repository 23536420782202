function Pagination({
  page,
  totalPages,
  totalResults,
  upButtonsDisabled,
  handlePageChange,
  simplified,
}) {
  if (totalPages <= 1) return null;

  let numbers = [];

  switch (true) {
    case totalPages <= 9:
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(
          <li
            className={classNames('page-item desktop-only', {
              active: i == page,
            })}
          >
            <button
              key={i}
              onClick={(e) => handlePageChange(i)}
              className='page-link'
              disabled={totalPages <= 1}
            >
              {i}
            </button>
          </li>
        );
      }
      break;
    case page <= 3 || page >= totalPages - 4:
      for (let i = 1; i <= 4; i++) {
        numbers.push(
          <li
            key={i}
            className={classNames('page-item desktop-only', {
              active: i == page,
            })}
          >
            <button
              onClick={(e) => handlePageChange(i)}
              className='page-link'
            >
              {i}
            </button>
          </li>
        );
      }
      numbers.push(
        <li key={0} className='page-item page gap desktop-only'>
          <span
            className='page-link'
            onClick={(e) => e.preventDefault()}
          >
            ...
          </span>
        </li>
      );
      for (let i = totalPages - 3; i <= totalPages; i++) {
        numbers.push(
          <li
            key={i}
            className={classNames('page-item desktop-only', {
              active: i == page,
            })}
          >
            <button
              onClick={(e) => handlePageChange(i)}
              className='page-link'
            >
              {i}
            </button>
          </li>
        );
      }
      break;
    default:
      numbers.push(
        <li
          key={1}
          className={classNames('page-item desktop-only', {
            active: 1 == page,
          })}
        >
          <button
            onClick={(e) => handlePageChange(1)}
            className='page-link'
          >
            {1}
          </button>
        </li>
      );
      numbers.push(
        <li key={-1} className='page-item page gap desktop-only'>
          <span
            className='page-link'
            onClick={(e) => e.preventDefault()}
          >
            ...
          </span>
        </li>
      );
      for (let i = page - 2; i <= page + 2; i++) {
        numbers.push(
          <li
            key={i}
            className={classNames('page-item desktop-only', {
              active: i == page,
            })}
          >
            <button
              onClick={(e) => handlePageChange(i)}
              className='page-link'
            >
              {i}
            </button>
          </li>
        );
      }
      numbers.push(
        <li key={0} className='page-item page gap desktop-only'>
          <span
            className='page-link'
            onClick={(e) => e.preventDefault()}
          >
            ...
          </span>
        </li>
      );
      numbers.push(
        <li
          key={totalPages}
          className={classNames('page-item desktop-only', {
            active: totalPages == page,
          })}
        >
          <button
            onClick={(e) => handlePageChange(totalPages)}
            className='page-link'
          >
            {totalPages}
          </button>
        </li>
      );
      break;
  }

  let selectOptions = [];
  for (var i = 0; i < totalPages; i++) {
    selectOptions.push(
      <option key={i} value={i + 1} data-total={totalPages}>
        {i + 1 == page ? i + 1 + ' / ' + totalPages : i + 1}
      </option>
    );
  }

  return (
    <ul
      className={classNames('pagination', {
        simplified: simplified,
        full: !simplified,
      })}
    >
      {/* <div className='total-results'>
        {I18n.t('total')}: {totalResults}
      </div> */}
      <li className='page-item'>
        <button
          className='page-link prev'
          onClick={(e) => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          <svg width='18' height='18' viewBox='0 0 24 24'>
            <path
              fill='none'
              fillRule='evenodd'
              className='stroke'
              stroke='#333D4C'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M15 20l-8-8 8-8'
            />
          </svg>
        </button>
      </li>
      {numbers}
      <li className='page-item mobile-only page-select'>
        <div className='select-wrapper'>
          <select
            name='page'
            value={page}
            onChange={(e) => handlePageChange(e.target.value)}
          >
            {selectOptions}
          </select>
        </div>
      </li>
      <li className='page-item'>
        <button
          className='page-link next'
          onClick={(e) => handlePageChange(page + 1)}
          disabled={page >= totalPages}
        >
          <svg width='18' height='18' viewBox='0 0 24 24'>
            <path
              fill='none'
              fillRule='evenodd'
              className='stroke'
              stroke='#333D4C'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M9 4l8 8-8 8'
            />
          </svg>
        </button>
      </li>
    </ul>
  );
}

export default Pagination;
