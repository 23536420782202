function ManufacturerModalBody({ manufacturer }) {
  return (
    <div className='contract-modal-body'>
      <div className='upload-container'>
        <div className='files'>
          {manufacturer.uploads.map((i) => (
              <a
                href={i.asset_url}
                target='_blank'
                key={i.uuid}
                className='file'
              >
                <div className='preview'>
                  <i className='far fa-file'></i>
                </div>
                <div className='filename'>{i.filename}</div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ManufacturerModalBody;
