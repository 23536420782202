import Helper from '../helpers/helper'
import Input from './input'
import Select from './select'
import Device from './device'

function DeviceJobDetail({ contract, deviceJob }) {
  let device = deviceJob.device
  if (!device) return null

  let contractCheckboxes = []
  let availableJobs = []
  if (contract.action_type == 'installation') {
    availableJobs = window.helperData.contract.installation_jobs
  } else if (contract.action_type == 'flue_inspection_report') {
    availableJobs = []
  } else {
    availableJobs = window.helperData.contract.standard_jobs
  }

  availableJobs.forEach(job => {
    contractCheckboxes.push(
      <tr key={job}>
        <td>{I18n.t(`activerecord.attributes.contract.${job}`)}</td>
        <td className="text-center">
          {deviceJob[job] ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
        </td>
      </tr>
    )
  })

  return (
    <div key={deviceJob.uuid} className='card bg-light mb-4'>
      <div className='card-header'>
        <Device device={device} />
      </div>
      <table className="table table-sm table-hover contract-detail">
        <tbody>
          {contractCheckboxes}
        </tbody>
        
        {!['installation','flue_inspection_report'].includes(contract.action_type) ? (
          <>
            <tbody className={classNames({'d-none': !contract.device_jobs.find(i => i.job_9 == true)})}>
              <tr>
                <td>{I18n.t('activerecord.attributes.contract.carbon_air')}</td>
                <td className="nowrap">
                  {deviceJob.carbon_air ? deviceJob.carbon_air + ' ppm' : null}
                </td>
              </tr>

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.carbon_flue_gas')}</td>
                <td className="nowrap">
                  {deviceJob.carbon_flue_gas ? deviceJob.carbon_flue_gas + ' ppm' : null}
                </td>
              </tr>

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.flue_gas_temp')}</td>
                <td className="nowrap">
                  {deviceJob.flue_gas_temp ? deviceJob.flue_gas_temp + ' °C' : null}
                </td>
              </tr>

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.chimney_draft')}</td>
                <td className="nowrap">
                  {deviceJob.chimney_draft ? deviceJob.chimney_draft + ' Pa' : null}
                </td>
              </tr>
            </tbody>
            <tbody className={classNames({'d-none': !contract.device_jobs.find(i => i.job_9b == true)})}>
              <tr>
                <td>{I18n.t('activerecord.attributes.contract.co2_min')}</td>
                <td className="nowrap">
                  {deviceJob.co2_min ? deviceJob.co2_min + ' %' : null}
                </td>
              </tr>
              <tr>
                <td>{I18n.t('activerecord.attributes.contract.co2_max')}</td>
                <td className="nowrap">
                  {deviceJob.co2_max ? deviceJob.co2_max + ' %' : null}
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <td>{I18n.t('activerecord.attributes.contract.gas_path_inspection')}</td>
                <td className="text-center">
                  <i className={classNames('fas', {
                      'fa-check text-success': deviceJob.gas_path_inspection,
                      'fa-times text-danger': !deviceJob.gas_path_inspection
                    })}></i>
                </td>
              </tr>

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.gas_path_inspection_at')}</td>
                <td className="nowrap">
                  {deviceJob.gas_path_inspection_at ? moment(deviceJob.gas_path_inspection_at).format('MMMM Y') : ''}
                </td>
              </tr>

              <tr>
                <td>{I18n.t('activerecord.attributes.contract.next_service_in_months')}</td>
                <td className="nowrap">
                  {deviceJob.next_service_in_months ? deviceJob.next_service_in_months + ' měsíců' : null}
                </td>
              </tr>
            </tbody>
          </>
        ) : null}

        {contract.action_type == 'flue_inspection_report' ? (
          <tbody>
            {_.range(4, 18).map(i =>
              <tr key={i}>
                <td>{I18n.t(`activerecord.attributes.contract.flue_${i}`)}</td>
                <td>
                  {deviceJob[`flue_${i}`]}
                </td>
              </tr>
            )}
          </tbody>
        ) : null}
      </table>
    </div>
  )
}

export default DeviceJobDetail
 