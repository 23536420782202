function ConsultationJobsDetail({ contract }) {

  let consultation_jobs = contract.consultation_jobs

  return (
    <>
      {[
        'current_device_manufacturer',
        'current_device_type',
        'current_device_place',
        'planned_device_manufacturer',
        'planned_device_type'
      ].map(i => (
        <tr key={i}>
          <td>{I18n.t(`consultation_jobs.${i}`)}</td>
          <td className="text-center">
            {consultation_jobs[i]}
          </td>
        </tr>
      ))}

      {[
        'regulation',
        'equithermal_sensor'
      ].map(i => (
        <tr key={i}>
          <td>{I18n.t(`consultation_jobs.${i}`)}</td>
          <td className="text-center">
            {consultation_jobs[i] ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
          </td>
        </tr>
      ))}

        {[
        'water_diameter',
        'water_material',
        'gas_diameter',
        'gas_material',
        'heat_diameter',
        'heat_material',
        'waste_diameter',
        'waste_material'
      ].map(i => (
        <tr key={i}>
          <td>{I18n.t(`consultation_jobs.${i}`)}</td>
          <td className="text-center">
            {consultation_jobs[i]}{i.includes('diameter') ? ' “' : ''}
          </td>
        </tr>
      ))}

      <tr>
        <td>{I18n.t(`consultation_jobs.condensate_pump`)}</td>
        <td className="text-center">
          {consultation_jobs.condensate_pump ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
        </td>
      </tr>

      {['heat', 'hygienic'].map(i => (
        <React.Fragment key={i}>
          <tr key={i}>
            <td>{I18n.t(`consultation_jobs.${i}_external_expanse`)}</td>
            <td className="text-center">
              {consultation_jobs[`${i}_external_expanse`] ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
            </td>
          </tr>

          {contract.consultation_jobs[`${i}_external_expanse`] && (
            <tr>
              <td>{I18n.t(`consultation_jobs.${i}_external_expanse_size`)}</td>
              <td className="text-center">
                {consultation_jobs[`${i}_external_expanse_size`]} l
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}

      <tr>
        <td>{I18n.t(`consultation_jobs.magnetic_filter`)}</td>
        <td className="text-center">
          {consultation_jobs.magnetic_filter}
        </td>
      </tr>

      <tr>
        <td>{I18n.t(`consultation_jobs.electro_job`)}</td>
        <td className="text-center">
        {consultation_jobs.electro_job ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
        </td>
      </tr>

      {contract.consultation_jobs.electro_job && (
        <tr>
          <td>{I18n.t(`consultation_jobs.electro_job_description`)}</td>
          <td className="text-center">
            {consultation_jobs.electro_job_description}
          </td>
        </tr>
      )}

      <tr>
        <td>{I18n.t(`consultation_jobs.old_device_liquidation`)}</td>
        <td className="text-center">
        {consultation_jobs.old_device_liquidation ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
        </td>
      </tr>

      {contract.consultation_jobs.old_device_liquidation && (
        <>
          <tr>
            <td>{I18n.t(`consultation_jobs.floor`)}</td>
            <td className="text-center">
              {consultation_jobs.floor}
            </td>
          </tr>
          <tr>
            <td>{I18n.t(`consultation_jobs.lift`)}</td>
            <td className="text-center">
              {consultation_jobs.lift ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
            </td>
          </tr>
        </>
      )}
      
    </>
  )
}

export default ConsultationJobsDetail
 