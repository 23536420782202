import MasterContainer from '../components/master_container'
import Modal from '../components/modal'
import Whisper from '../components/whisper'
import * as Sentry from '@sentry/browser'

// import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import 'popper.js'
import 'bootstrap'

import 'javascripts/i18n/translations.js'
import 'helpers/script_google_maps.js'

// CSS
import 'css/application.sass'

// Version
window.version = 229

try {
  window.environment = process.env.RAILS_ENV
}
catch(err) {
  console.log('Could not set environment')
  console.log(err.message)
}

// Sentry
if (!(environment && environment == 'development')) {
  Sentry.init({ dsn: "https://400beffd0c2744eababc896db0130305@sentry.io/2814507", environment: environment });
  window.Sentry = Sentry
}

// OfflinePlugin
// OfflinePluginRuntime.install();

// Setup moment locale
window.moment = moment
moment.locale('cs')

// Locales
I18n.locale = 'cs'

// HelperData
require('../helpers/helper_data.js')

window.mountedComponents = []
window.mountedWhispers = []
window.whispers = () => {
  const whispers = document.querySelectorAll('input[data-whisper]')
  whispers.forEach( element => {
    if (!element.dataset.whisperMounted) {
      element.setAttribute('autocomplete','off')
      element.dataset.whisperMounted = true
      element.parentNode.classList.add('whisper-wrapper')
      let div = document.createElement('div')
      div.classList.add('whisper-container')
      element.parentNode.appendChild(div)
      let props = {input: element}
      if (element.dataset.props) {
        Object.assign(JSON.parse(element.dataset.props), {
          input: element
        })
      }
      let component = ReactDOM.render(
        React.createElement(Whisper, props),
        div
      )
      window.mountedComponents.push(component)
      window.mountedWhispers.push(component)
    }
  })
}


let master
const domReady = function() {
  // MasterContainer
  master = ReactDOM.render(
    React.createElement(MasterContainer),
    document.getElementById('master-container')
  )

  window.whispers()
  window.master = master
}

document.addEventListener('DOMContentLoaded', domReady)

$(window).bind('beforeunload', () => {
  try {
    ReactDOM.unmountComponentAtNode(document.getElementById('master-container'))
  }
  catch(error) {
    // console.log(error)
  }
})

// Disable scroll on input type number
jQuery(document).on("wheel", "input[type=number]", function (e) {
  jQuery(this).blur()
})

// Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(registration => {
      window.registration = registration

      registration.addEventListener('updatefound', () => {

        // An updated service worker has appeared in reg.installing!
        let newWorker = registration.installing;

        newWorker.addEventListener('statechange', () => {

          // Has service worker state changed?
          switch (newWorker.state) {
            case 'installed':

	            // There is a new service worker available, show the notification
              if (navigator.serviceWorker.controller) {
                // let modalBody = (
                //   <div>Byla stažena aktualizace aplikace.</div>
                // )
                //
                // ReactDOM.render(
                //   React.createElement(Modal, {
                //     title: 'Aktualizace',
                //     body: modalBody,
                //     linkTitle: 'Aktualizovat',
                //     refresh: true
                //   }),
                //   document.querySelector('#modal-container')
                // )

                master.setState({updateAvailable: true})
              }
              break;
          }
        })
      })

    }).catch(registrationError => {
      // console.log('SW registration failed: ', registrationError);
    });
  });
}
