import defaultAvatar from 'images/avatar.png'

class Comment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let timeRow = [
      <span key={0}>{moment(this.props.comment.created_at).format('LLL')}</span>
    ]

    if (this.props.showContractId && this.props.comment.contract_id) {
      timeRow.push(
        <React.Fragment key={1}>
          <span> | </span>
          <span>
            Zakázka {this.props.comment.contract_id}
          </span>
        </React.Fragment>
      )
    }

    if (window.currentUser && this.props.comment.user_id == window.currentUser.id) {
      timeRow.push(
        <React.Fragment key={2}>
          <span> | </span>
          <a
            href="#"
            className="text-danger"
            onClick={this.props.handleDelete.bind(this, this.props.comment)}>
            Smazat
          </a>
        </React.Fragment>
      )
    }

    return (
      <div className="comment change">
        <div className="body">
          <div className="time">
            {timeRow.map(i => i)}
          </div>
          <div className="message">{this.props.comment.text}</div>
        </div>
        <div className="user">
          <div className="avatar">
            <img src={this.props.comment.user ? this.props.comment.user.avatar_url : defaultAvatar} alt=""/>
          </div>
          <div className="name">{this.props.comment.user ? this.props.comment.user.name : 'Smazaný'}</div>
        </div>
      </div>
    )
  }
}

export default Comment
