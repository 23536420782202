const Helper = {
  hourHeight: 6,
  formatMoneyOld: (amount, decimalCount = 2, decimal = ",", thousands = " ") => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    if (decimalCount == 0) {
      amount = Math.round(amount * 100) / 100
    }

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  },
  formatMoney: (amount) => {
    let formatted = Intl.NumberFormat(
      'cs-CZ',
      {
        style: "currency",
        currency: "CZK"
      }
    ).format(amount).replace(' Kč', '')

    if (formatted == 'NaN') return '0,00'

    return formatted
  },
  formatPhone: (value) => {
    let formatted = Intl.NumberFormat('cs-CZ').format(value)
    if (formatted == 'NaN') return value

    return formatted
  },
  uuid: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
  },

  isHoliday: (date) => {
    return Helper.fixedDays(date.getFullYear()).filter( i => i.date.toISOString().split('T')[0] == date.toISOString().split('T')[0] ).length ? true : false
  },

  holidayName: (date) => {
    if (Helper.isHoliday(date)) {
      return Helper.fixedDays(date.getFullYear()).find(i => i.date.toISOString().split('T')[0] == date.toISOString().split('T')[0]).name
    } else {
      return ''
    }
  },

  fixedDays: (year) => {
    // Easter Sunday
    let dayPoint = Helper.easterSunday(year)
    let dayPointFriday = new Date(dayPoint)
    dayPointFriday.setDate(dayPoint.getDate() - 2)
    let dayPointMonday = new Date(dayPoint)
    dayPointMonday.setDate(dayPoint.getDate() + 1)

    let dates = [
      {date: new Date(year,1-1,1), name: "Den obnovy samostatného českého státu a Nový rok"},
      {date: dayPointFriday, name: "Velký pátek"},
      {date: dayPointMonday, name: "Velikonoční pondělí"},
      {date: new Date(year,5-1,1), name: "Svátek práce"},
      {date: new Date(year,5-1,8), name: "Den vítězství"},
      {date: new Date(year,7-1,5), name: "Den slovanských věrozvěstů Cyrila a Metoděje"},
      {date: new Date(year,7-1,6), name: "Den upálení mistra Jana Husa"},
      {date: new Date(year,9-1,28), name: "Den české státnosti"},
      {date: new Date(year,10-1,28), name: "Den vzniku samostatného československého státu"},
      {date: new Date(year,11-1,17), name: "Den boje za svobodu a demokracii"},
      {date: new Date(year,12-1,24), name: "Štědrý den"},
      {date: new Date(year,12-1,25), name: "1. svátek vánoční"},
      {date: new Date(year,12-1,26), name: "2. svátek vánoční"}
    ]

    return dates
  },

  easterSunday: (year) => {
    let a = year % 19
    let b = year % 4
    let c = year % 7
    let m = 24
    let n = 5
    let d = (19*a + m) % 30
    let e = (n + 2*b + 4*c + 6*d) % 7
    let u = d + e - 9

    let dayPoint
    if (u == 25 && d == 28 && e == 6 && a > 10) {
      dayPoint = new Date(year,4-1,18)
    } else if (u >= 1 && u <= 25) {
      dayPoint = new Date(year,4-1,u)
    } else if (u > 25) {
      u = u - 7
      dayPoint = new Date(year,4-1,u)
    } else {
      u = 22 + d + e
      dayPoint = new Date(year,3-1,u)
    }

    return dayPoint
  },

  dayType: (date) => {
    if (Helper.isHoliday(date) || date.getDay() == 0) {
      return 2
    } else if (date.getDay() == 6) {
      return 1
    } else {
      return 0
    }
  },

  timeFormatted: (seconds) => {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.round(((seconds / 3600) - hours) * 60)

    return `${hours}:${minutes}`
  },

  loadAres: ({ e, component, vatNumber, placeUUID, scenario } = { placeUUID: '', scenario: '' }) => {
    e.preventDefault()

    const currentRequest = jQuery.ajax({
      method: 'POST',
      url: '/api/v1/load-ares',
      data: {
        id: vatNumber
      },
      beforeSend: (xhr) => {
        if (component.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + component.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (component.currentRequest) {
          component.currentRequest.abort()
        }
        component.setState({
          loadingAres: true
        })
        component.currentRequest = null
      },
      success: (data) => {
        if (!data) {
          component.setState({loadingAres: false})
          return
        }

        if (placeUUID) {
          component.setState(state => {
            let places = state.customer.places.map(i => {
              if (i.uuid == placeUUID) {
                return Object.assign({}, i, {
                  billing_name: data.name,
                  billing_city: data.city,
                  billing_street: data.street,
                  billing_tax_no: data.tax_no,
                  billing_zip: data.zip,
                  billing_vat_payer: (data.vat_payer || false),
                  errors: Object.assign(
                    {},
                    i.errors,
                    { billing_vat_payer: (data.vies_error ? ['Nepodařilo se načíst ze systému VIES'] : null) }
                  )
                })
              } else {
                return i
              }
            })
            return {
              loadingAres: false,
              customer: Object.assign({}, state.customer, {
                places: places
              })
            }
          })

          return
        }

        if (scenario == 'billing_address') {
          component.setState(state => {
            return {
              loadingAres: false,
              customer: Object.assign({}, state.customer, {
                billing_name: data.name,
                billing_tax_no: data.tax_no,
                city: data.city,
                street: data.street,
                zip: data.zip,
                has_billing_address: true,
                vat_payer: (data.vat_payer || false),
                errors: Object.assign(
                  {},
                  state.customer.errors,
                  { vat_payer: (data.vies_error ? ['Nepodařilo se načíst ze systému VIES'] : null) }
                )
              })
            }
          })

          return
        }

        if (
          typeof google !== 'undefined' &&
          component.state.customer &&
          component.state.customer.places[0] &&
          !component.state.customer.places[0].street
        ) {
          var address = [data.street, data.zip, data.city].join(', ')
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode( { 'address': address}, function(results, status) {
            if (status == 'OK') {
              if (results[0]) {
                component.setState(state => {
                  let places = state.customer.places
                  Object.assign(places[0], {
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng()
                  })
                  customer: Object.assign(state.customer, {
                    places: places
                  })
                })
                component.forceUpdate()

                // Update map
                let mapIndex = document.querySelector('.place:not(.d-none) .address-wrapper').dataset.map
                let map = window.googleMaps.find(i => i.index == mapIndex).map
                var latLng = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
                window.addMarker(latLng, mapIndex)
                map.setCenter(latLng)
                map.setZoom(12)
              }
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            }
          });
        }

        component.setState(state => {
          let places = state.customer.places
          if (places[0] && !places[0].street) {
            Object.assign(places[0], {
              street: data.street,
              city: data.city,
              zip: data.zip
            })
          }
          return {
            loadingAres: false,
            customer: Object.assign({}, state.customer, {
              name: data.name,
              city: data.city,
              street: data.street,
              tax_no: data.tax_no,
              zip: data.zip,
              has_billing_address: true,
              vat_payer: (data.vat_payer || false),
              places: places,
              errors: Object.assign(
                {},
                state.customer.errors,
                { vat_payer: (data.vies_error ? ['Nepodařilo se načíst ze systému VIES'] : null) }
              )
            })
          }
        })
      },
      error: (data) => {
        console.log(data)
      },
      complete: () => {
        component.setState({loading: false})
        component.currentRequest = null
      }
    })
    component.currentRequest = currentRequest
  }


}

export default Helper
