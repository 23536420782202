

class FormErrors extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className={classNames('alert alert-danger', {'d-none': !this.props.errors.length})}>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {this.props.errors.map(i =>
          <div key={i}>{i}</div>
        )}
      </div>
    )
  }
}

export default FormErrors
