import { useState, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import ShortMessage from './short_message';
import Loading from '../loading';

const defaultMessages = [
  {
    title: 'Servisní prohlídka plynového spotřebiče - A',
    text: 'Dobry den, dnes jsme vas kontaktovali na telefon, ale neuspesne. Prosime zavolejte nam na cislo 739888333 ohledne domluveni terminu na pravidelnou kontrolu plynoveho kotle. Dekujeme. AGA PLYNSERVIS',
  },
  {
    title: 'Servisní prohlídka plynového spotřebiče - B',
    text: 'Dobry den, nedovolali jsme se vam nekolikrat ohledne provedeni kontroly plynoveho kotle. Prosime kontaktujte nas na telefonu 739888333. Dekujeme a hezky den. AGA PLYNSERVIS',
  },
  {
    title: 'Sdělení termínu - SERVIS',
    text: 'Dobry den. Na zaklade objednavky ………… potvrzujeme termin pro servisni prohlidku plynoveho spotrebice. Technik prijede dne ………… v rozmezi hod: ………………. Dekujeme za objednavku. S pozdravem AGA PLYNSERVIS',
  },
  {
    title: 'Sdělení termínu - OPRAVA',
    text: 'Dobry den. Na zaklade objednavky ………… potvrzujeme termin pro opravu plynového spotrebice. Technik prijede dne ………… v rozmezi hod: ………………. Dekujeme za objednavku. S pozdravem AGA PLYNSERVIS',
  },
  {
    title: 'Sdělení termínu - TOPENÁŘSKÉ PRÁCE',
    text: 'Dobry den. Na zaklade objednavky ………… potvrzujeme termin pro topenare - instalatera. Technik prijede dne ………… v rozmezi hod: ………………. Dekujeme za objednavku. S pozdravem AGA PLYNSERVIS',
  },
];

const ShortMessages = ({ customerId, accessToken, phones }) => {
  let defaultPhone = phones.find((i) => i.default);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState(
    defaultPhone ? [defaultPhone.value] : []
  );
  const [shortMessages, setShortMessages] = useState([]);
  const [errors, setErrors] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const currentRequest = useRef(null);

  useEffect(() => {
    setShortMessages([]);
    fetchShortMessages();
  }, [customerId]);

  const fetchShortMessages = ({ silent } = { silent: false }) => {
    currentRequest.current = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/short_messages',
      data: {
        customer_id: customerId,
      },
      beforeSend: (xhr) => {
        if (accessToken) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + accessToken.access_token
          );
        } else {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + document.body.dataset.jwt
          );
        }

        if (currentRequest.current) {
          currentRequest.current.abort();
        }
        if (!silent) setLoading(true);
        currentRequest.current = null;
      },
      success: (data) => {
        setShortMessages(data.results);
      },
      complete: () => {
        if (!silent) setLoading(false);
        currentRequest.current = null;
      },
    });
    currentRequest.current = currentRequest;
  };

  const postShortMessage = () => {
    currentRequest.current = jQuery.ajax({
      method: 'POST',
      url: '/api/v1/short_messages',
      data: {
        short_message: {
          customer_id: customerId,
          message,
          recipients,
        },
      },
      beforeSend: (xhr) => {
        if (accessToken) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + accessToken.access_token
          );
        } else {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + document.body.dataset.jwt
          );
        }

        if (currentRequest.current) {
          currentRequest.current.abort();
        }
        setLoading(true);
        currentRequest.current = null;
      },
      success: (data) => {
        let newShortMessages = shortMessages.concat();
        newShortMessages.unshift(data);
        setShortMessages(newShortMessages);

        setRecipients([]);
        setMessage('');
        setErrors({});
      },
      error: (data) => {
        if (data.responseJSON) {
          setErrors(data.responseJSON.errors);
        }
      },
      complete: () => {
        setLoading(false);
        currentRequest.current = null;
      },
    });
    currentRequest.current = currentRequest;
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    postShortMessage();
  };

  const disabled =
    !message.replace(/\s/g, '').length || loading || recipients.length == 0;

  const handleRecipientsChange = (data) => {
    setRecipients(data?.map((i) => i.value) || []);
  };

  return (
    <React.Fragment>
      <div className='comment-form'>
        <div className='row'>
          <div className='col'>
            <div
              className={classNames('form-group react-common-select', {
                'is-invalid': errors.recipients,
              })}
            >
              {/* <label htmlFor='' className='form-control-label'>
            E-mail
          </label> */}
              <CreatableSelect
                isMulti
                value={recipients.map((i) => {
                  if (i) {
                    let phone = phones.find((ii) => ii.value == i);
                    return {
                      value: i,
                      label: `${i} ${phone ? phone.note : ''}`,
                    };
                  }
                })}
                onChange={handleRecipientsChange}
                options={phones.map((i) => {
                  return {
                    value: i.value,
                    label: `${i.value} ${i.note}`,
                  };
                })}
                placeholder='Příjemci'
                noOptionsMessage={function () {
                  return 'Nic nenalezeno';
                }}
                isClearable={true}
                formatCreateLabel={(i) => `Číslo: ${i}`}
              />
              {errors.recipients && (
                <div className='invalid-feedback'>{errors.recipients[0]}</div>
              )}
            </div>
          </div>
          <div className='col-auto'>
            <div className='dropdown'>
              <button
                className='btn btn-outline-secondary dropdown-toggle'
                onClick={(e) => {
                  e.preventDefault();
                  setDropdownVisible(!dropdownVisible);
                }}
              >
                Předvyplnit text
              </button>
              <div
                className={classNames('dropdown-menu dropdown-menu-right', {
                  show: dropdownVisible,
                })}
              >
                {defaultMessages.map((i) => (
                  <a
                    key={i.title}
                    href='#'
                    className='dropdown-item'
                    onClick={(e) => {
                      e.preventDefault();
                      setMessage(i.text);
                      setDropdownVisible(false);
                    }}
                  >
                    {i.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <textarea
            placeholder='Napište SMS'
            className='form-control'
            name='text'
            id='text'
            value={message}
            onChange={handleChange}
            rows={3}
          ></textarea>
        </div>
        <div className='text-right'>
          <button
            disabled={disabled}
            className='btn btn-success'
            onClick={handleSubmit}
          >
            Odeslat SMS
          </button>
        </div>
      </div>
      <div className='comments changelog'>
        {loading ? <Loading /> : null}

        {shortMessages.map((shortMessage) => (
          <ShortMessage key={shortMessage.id} shortMessage={shortMessage} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default ShortMessages;
