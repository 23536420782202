import Helper from '../helpers/helper'
import Input from './input'
import Select from './select'
import Device from './device'

function DeviceJob(props) {
  let availableJobs = []
  if (props.contract.action_type == 'installation') {
    availableJobs = window.helperData.contract.installation_jobs
  } else if (props.contract.action_type == 'flue_inspection_report') {
    availableJobs = []
  } else {
    availableJobs = window.helperData.contract.standard_jobs
  }

  let device_job = props.contract.device_jobs.find(i => i.device_uuid == props.device.uuid) || {
    checked: false,
    uuid: Helper.uuid(),
    device_uuid: props.device.uuid,
  }

  let contractCheckboxes = []
  availableJobs.forEach(job => {
    contractCheckboxes.push(
      <Input
        id={`${device_job.uuid}_${job}`}
        key={job}
        name={job}
        type="checkbox"
        object={device_job}
        label={I18n.t(`activerecord.attributes.contract.${job}`)}
        onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
      />
    )
  })
  let options11 = ['A','B','C'].map(i => { return {value: i, label: i}} )
  let options13 = ['Ano','Ne'].map(i => { return {value: i, label: i}} )
  let options17 = [
    'Vyhovuje',
    'Vyhovuje po odstranění zjištěných závad',
    'Nevyhovuje'
  ].map(i => { return {value: i, label: i}} )

  let defaultCustomerName = ''
  if (props.contract?.customer && props.contract?.place) {
    defaultCustomerName = [
      props.contract.customer.name,
      props.contract.place.street,
      props.contract.place.zip,
      props.contract.place.city
    ].join(', ')
  }

  return (
    <div className={classNames('card bg-light mb-4', {'d-none': !device_job.checked})}>
      <div className='card-header device'>
        <div className="name">
          <Device device={props.device} />
        </div>
      </div>
      <div className="card-body">
        <div className='row'>
          <div className='col-sm-6'>
            {contractCheckboxes.map(i => i)}
          </div>

          <div className={classNames('col-sm-6', {'d-none': ['installation', 'flue_inspection_report'].includes(props.contract.action_type)})}>
            <div className={classNames({'d-none': !props.contract.device_jobs.find(i => i.job_9 == true)})}>
              <Input
                id={`${device_job.uuid}_carbon_air`}
                name="carbon_air"
                type="number"
                append="ppm"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.carbon_air`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />

              <Input
                id={`${device_job.uuid}_carbon_flue_gas`}
                name="carbon_flue_gas"
                type="number"
                append="ppm"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.carbon_flue_gas`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />

              <Input
                id={`${device_job.uuid}_flue_gas_temp`}
                name="flue_gas_temp"
                type="number"
                append="°C"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.flue_gas_temp`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />

              <Input
                id={`${device_job.uuid}_chimney_draft`}
                name="chimney_draft"
                type="text"
                append="Pa"
                defaultValue="-"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.chimney_draft`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />
            </div>

            <div className={classNames({'d-none': !props.contract.device_jobs.find(i => i.job_9b == true)})}>
              <Input
                id={`${device_job.uuid}_co2_min`}
                name="co2_min"
                type="number"
                append="%"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.co2_min`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)} />
              <Input
                id={`${device_job.uuid}_co2_max`}
                name="co2_max"
                type="number"
                append="%"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.co2_max`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)} />
            </div>

            <Input
              id={`${device_job.uuid}_gas_path_inspection`}
              name="gas_path_inspection"
              type="checkbox"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.gas_path_inspection`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
            />

            <div className={classNames({'d-none': !device_job.gas_path_inspection})}>
              <Input
                id={`${device_job.uuid}_gas_path_inspection_at`}
                name="gas_path_inspection_at"
                type="month"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.gas_path_inspection_at`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />
            </div>

            <Input
              id={`${device_job.uuid}_next_service_in_months`}
              name="next_service_in_months"
              type="number"
              append="měsíců"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.next_service_in_months`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
            />
          </div>

        </div>
        {props.contract.action_type == 'flue_inspection_report' && (
          <>
            <Input
              id={`${device_job.uuid}_flue_0`}
              name="flue_0"
              type="date"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.flue_0`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
            />
            {[1,2,3,4,5].map((i) =>
              <Input
                key={`flue_${i}`}
                id={`${device_job.uuid}_flue_${i}`}
                name={`flue_${i}`}
                type="textarea"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.flue_${i}`)}
                placeholder={[1,2,3].includes(i) ? defaultCustomerName : undefined}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />
            )}

            <Input
              id={`${device_job.uuid}_flue_6`}
              name="flue_6"
              type="text"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.flue_6`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
            />

            <div className="row">
              {[7,8,9].map(i =>
                <div className="col" key={`flue_${i}`}>
                  <Input
                    id={`${device_job.uuid}_flue_${i}`}
                    name={`flue_${i}`}
                    type="text"
                    object={device_job}
                    label={I18n.t(`activerecord.attributes.contract.flue_${i}`)}
                    onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col">
                <Input
                  id={`${device_job.uuid}_flue_10`}
                  name="flue_10"
                  type="text"
                  object={device_job}
                  label={I18n.t(`activerecord.attributes.contract.flue_10`)}
                  onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
                />
              </div>
              <div className="col">
                <Select
                  id={`${device_job.uuid}_flue_11`}
                  name="flue_11"
                  object={device_job}
                  label={I18n.t(`activerecord.attributes.contract.flue_11`)}
                  onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
                  options={options11}
                />
              </div>
              <div className="col">
                <Input
                  id={`${device_job.uuid}_flue_12`}
                  name="flue_12"
                  type="text"
                  object={device_job}
                  label={I18n.t(`activerecord.attributes.contract.flue_12`)}
                  onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
                />
              </div>
              <div className="col">
                <Select
                  id={`${device_job.uuid}_flue_13`}
                  name="flue_13"
                  object={device_job}
                  label={I18n.t(`activerecord.attributes.contract.flue_13`)}
                  onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
                  options={options13}
                />
              </div>
            </div>

            {[14,15].map(i =>
              <Input
                key={`flue_${i}`}
                id={`${device_job.uuid}_flue_${i}`}
                name={`flue_${i}`}
                type="textarea"
                object={device_job}
                label={I18n.t(`activerecord.attributes.contract.flue_${i}`)}
                onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              />
            )}

            <Input
              id={`${device_job.uuid}_flue_16`}
              name="flue_16"
              type="date"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.flue_16`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
            />

            <Select
              id={`${device_job.uuid}_flue_17`}
              name="flue_17"
              object={device_job}
              label={I18n.t(`activerecord.attributes.contract.flue_17`)}
              onChange={e => props.handleInputDeviceChange(device_job.uuid, e)}
              options={options17}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DeviceJob
