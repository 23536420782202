import Helper from '../helpers/helper'

class DocumentList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <table className="table table-sm table-sm-font">
        <thead>
          <tr>
            <th>Číslo</th>
            <th>Datum</th>
            <th>Typ</th>
            {this.props.contractView ? (
              <th>Zakázka</th>
            ) : null}
            <th className="text-right">Celkem vč. DPH</th>
            <th className="text-right">K úhradě</th>
            <th className="d-none">Owed</th>
            <th>Způsob platby</th>
            <th>Zaplaceno</th>
            {this.props.pdfView ? (
              <th></th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {this.props.documents.map(i =>
            <tr key={i.id}>
              <td>{i.number}</td>
              <td>{moment(i.date_of_issue).format('L')}</td>
              <td>{I18n.t(`document-kinds.${i.kind}`)}</td>
                {this.props.contractView ? (
                  <td>
                    {i.contract_id}
                  </td>
                ) : null}
              <td className="nowrap text-right">{Helper.formatMoney(i.total_vat_cache)}</td>
              <td className="nowrap text-right">{Helper.formatMoney(i.to_pay_cache)}</td>
              <td className="nowrap text-right d-none">{Helper.formatMoney(i.owed_cache)}</td>
              <td>{i.payment_type ? I18n.t(`payment-types.${i.payment_type}`) : null}</td>
              <td>
                {i.paid ? (
                  <span className="badge badge-success">Ano</span>
                ) : (
                  <span className="badge badge-danger">Ne</span>
                )}
              </td>
              {this.props.pdfView ? (
                <td>
                  <a href={i.pdf_url} target="_blank">
                    <i className="far fa-file-pdf"></i>
                  </a>
                </td>
              ) : null}
            </tr>
          )}
        </tbody>
      </table>
    )
  }
}

export default DocumentList
