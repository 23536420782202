import Loading from './loading'
import { useState, useEffect } from 'react'

function DeviceImages(props) {
  const [uploads, setUploads] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUploads()
  }, [])

  const fetchUploads = () => {
    jQuery.ajax({
      method: 'GET',
      dataType: 'json',
      url: '/api/v1/uploads',
      data: {
        device_uuid: props.deviceUuid
      },
      beforeSend: (xhr) => {
        setLoading(true)
        if (
          props.master &&
          props.master.state.accessToken &&
          props.master.state.accessToken.access_token
        ) {
          xhr.setRequestHeader ("Authorization", "Bearer " + props.master.state.accessToken.access_token);
        }
      },
      success: (response) => {
        setUploads(response.results)
      },
      complete: () => {
        setLoading(false)
      },
      error: (data) => {
      }
    });
  }

  if (loading) return (
    <Loading />
  )

  return (
    <div className={classNames('device-images', {'mt-3': props.pushTop && uploads.length})}>
      {uploads.map(upload =>
        <a
          key={upload.uuid}
          href={upload.asset_url}
          target="_blank"
          className="image">
          <img src={upload.asset_url} alt=""/>
        </a>
      )}
    </div>
  );
}

export default DeviceImages
