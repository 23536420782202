import Select from 'react-select'
import Helper from '../helpers/helper'

class CommonSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      defaultValue: '',
      currentRequest: null,
      loading: false
    };
  }

  handleChange(e) {
    if (this.props.inFilter) {
      setTimeout(function(){
        let form = document.querySelector('form.filter')
        if (form.dataset.remote) {
          Rails.fire(form, 'submit')
        } else {
          form.submit()
        }
      }, 100)
    }
  }

  customOption(data) {
    switch (this.props.type) {
      case 'place':
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className={classNames('option-custom padded', {'focused': data.isFocused})}>
              <div className="text">
                <div className="primary">{data.data.label}</div>
                <div className="secondary text-muted">
                  {data.data.devices.map((i, index) => {
                    let device = ''
                    if (i.manufacturer) device += i.manufacturer + ': '
                    if (i.type) device += i.type
                    if (i.note) device += ' - ' + i.note
                    return (
                      <div key={index}>{device}</div>
                    )
                  })}
                  Poslední cena dopravy: {data.data.last_transport_cost ? Helper.formatMoney(data.data.last_transport_cost) : '-'}
                </div>
              </div>
            </div>
          </div>
        )
        break

      default:
        return (
          <div ref={data.innerRef} {...data.innerProps}>
            <div className="option-custom padded">{data.data.label}</div>
          </div>
        )
    }
  }

  render() {
    let _this = this
    let defaultValue = ''
    if (this.props.value && this.props.options) {
      defaultValue = this.props.options.filter( i => { return i.value == this.props.value })[0]
    }

    return (
      <div className={classNames({'is-invalid': this.props.errors && this.props.errors.length})}>
        <Select
          name={this.props.name}
          multi={false}
          defaultValue={defaultValue}
          value={this.props.uncontrolled ? undefined : this.props.value}
          placeholder={this.props.placeholder || 'Hledat'}
          noOptionsMessage={function() { return 'Nic nenalezeno'}}
          isClearable={true}
          options={this.props.options}
          onChange={this.props.handleChange ? this.props.handleChange.bind(this) : this.handleChange.bind(this)}
          components={{ Option: _this.customOption.bind(this), SingleValue: _this.customOption.bind(this) }}
          isDisabled={this.props.disabled}
        />
      {this.props.errors && this.props.errors.length ? (
          <div className="invalid-feedback" style={{display: 'block'}}>{this.props.errors[0]}</div>
        ) : null}
      </div>
    )
  }
}

export default CommonSelect
