import Helper from '../helpers/helper'

class LogChange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  toggleCollapse() {
    this.setState((state, props) => {
      return {
        collapsed: !state.collapsed
      }
    })
  }

  getHumanValue(name, value, id) {
    switch(name) {
      case 'status':
        return I18n.t(`statuses.${value}`)
        break
      case 'action_type':
        return I18n.t(`action-types.${value}`)
        break
      case 'payment_type':
        if (value) {
          return I18n.t(`payment-types.${value}`)
        } else {
          return ''
        }
        break
      case 'items':
        return (
          <table>
            <tbody>
              {value.map(i =>
                <tr key={`${id}_${i.id}`}>
                  <td>{i.name}</td>
                  <td>{i.amount}</td>
                  <td className="nowrap">{Helper.formatMoney(i.value)}</td>
                </tr>
              )}
            </tbody>
          </table>
        )
        break
      case 'device_jobs':
        return (
          value.filter(device_job => device_job.checked).map(device_job => {
            let device = device_job.device
            if (!device) return null
            let contractCheckboxes = []
            let availableJobs = []
            if (this.props.object.action_type == 'installation') {
              availableJobs = window.helperData.contract.installation_jobs
            } else {
              availableJobs = window.helperData.contract.standard_jobs
            }
            availableJobs.forEach(job => {
              contractCheckboxes.push(
                <tr key={job}>
                  <td>{I18n.t(`activerecord.attributes.contract.${job}`)}</td>
                  <td className="text-center">
                    {device_job[job] ? (<i className="fas fa-check text-success"></i>) : (<i className="fas fa-times text-danger"></i>)}
                  </td>
                </tr>
              )
            })

            return (
              <table key={device_job.uuid} className="table table-sm table-hover contract-detail">
                <thead>
                  <tr>
                    <th colSpan="2">
                      {device.manufacturer}: {device.type} - {device.note}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contractCheckboxes}
                  <tr className={classNames({'d-none': this.props.object.action_type == 'installation'})}>
                    <td>{I18n.t('activerecord.attributes.contract.carbon_air')}</td>
                    <td className="nowrap">
                      {device_job.carbon_air ? device_job.carbon_air + ' ppm' : null}
                    </td>
                  </tr>

                  <tr className={classNames({'d-none': this.props.object.action_type == 'installation'})}>
                    <td>{I18n.t('activerecord.attributes.contract.carbon_flue_gas')}</td>
                    <td className="nowrap">
                      {device_job.carbon_flue_gas ? device_job.carbon_flue_gas + ' ppm' : null}
                    </td>
                  </tr>

                  <tr className={classNames({'d-none': this.props.object.action_type == 'installation'})}>
                    <td>{I18n.t('activerecord.attributes.contract.flue_gas_temp')}</td>
                    <td className="nowrap">
                      {device_job.flue_gas_temp ? device_job.flue_gas_temp + ' °C' : null}
                    </td>
                  </tr>

                  <tr className={classNames({'d-none': this.props.object.action_type == 'installation'})}>
                    <td>{I18n.t('activerecord.attributes.contract.chimney_draft')}</td>
                    <td className="nowrap">
                      {device_job.chimney_draft ? device_job.chimney_draft + ' Pa' : null}
                    </td>
                  </tr>

                  <tr className={classNames({'d-none': this.props.object.action_type == 'installation'})}>
                    <td>{I18n.t('activerecord.attributes.contract.next_service_in_months')}</td>
                    <td className="nowrap">
                      {device_job.next_service_in_months ? device_job.next_service_in_months + ' měsíců' : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
        )
        break
      default:
        let returnValue = JSON.stringify(value)
        switch(returnValue) {
          case 'false':
            return <i className="fas fa-times text-danger"></i>
            break
          case 'true':
            return <i className="fas fa-check text-success"></i>
            break
          case 'null':
            return ''
            break
          default:
            return returnValue
        }
    }
  }

  render() {

    return (
      <div className="change-wrapper">
        <div className="change">
          <div className="body">
            <div className="time">{moment(this.props.object.time).format('LLL')}</div>
            <div className="message">
              <span className="toggler" onClick={this.toggleCollapse.bind(this)}>
                {this.props.object.message}
              </span>
            </div>
          </div>
          <div className="user">
            <div className="avatar">
              {this.props.user ? (
                <img src={this.props.user.avatar_url} alt=""/>
              ) : null}
            </div>
            <div className="name">{this.props.object.user_name}</div>
          </div>
        </div>
        <div className={classNames('json-wrapper', {'d-none': this.state.collapsed})}>
          <table className="table">
            <thead>
              <tr>
                <th style={{width: '30%'}}></th>
                <th>Před</th>
                <th>Po</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.props.object.changes).map(key =>
                <tr key={key}>
                  <th>{I18n.t(`activerecord.attributes.contract.${key}`, {defaultValue: I18n.t(`attributes.${key}`)})}</th>
                  <td>
                    {this.getHumanValue(key, this.props.object.changes[key][0], `first_${key}`)}
                  </td>
                  <td>
                    {this.getHumanValue(key, this.props.object.changes[key][1], `second_${key}`)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

    )
  }
}

export default LogChange
